import { useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { calculateMaximumContribution } from "../../../../../utils";
import useStepper from "../../../../../hooks/useStepper";

function MaxContributionCalculationForm() {
  const { values, setFieldValue } = useFormikContext();
  const { formValues } = useStepper();

  useEffect(() => {
    const dateOfBirth = values.date_of_birth || formValues.date_of_birth;
    const calculatedAgeFromBirth = dateOfBirth
      ? moment().diff(dateOfBirth, "years")
      : 0;

    if (values.income && values.hours_per_week && calculatedAgeFromBirth) {
      const maximumContribution = calculateMaximumContribution({
        income: values.income,
        hours_per_week: values.hours_per_week,
        age: calculatedAgeFromBirth,
        salarySacrifice: values.salarySacrifice || 0,
      });

      setFieldValue("max_contribution_amount", maximumContribution || 0);
    }
  }, [
    values.income,
    values.hours_per_week,
    formValues.date_of_birth,
    values.date_of_birth,
    values.salarySacrifice,
  ]);

  return <></>;
}

export default MaxContributionCalculationForm;
