import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { checkFieldError } from "../../../helpers";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import useStepper from "../../../hooks/useStepper";
import { useState } from "react";

const DateInput = ({
  id,
  name,
  label,
  value,
  styleClass,
  key = true,
  trackingDetails,
  handleChangeValue,
  handleBlurValue,
  minDate,
  maxDate,
  openMonth = false,
  openToCalender = false,
  disabled = false,
}) => {
  const { touched, errors } = useFormikContext();
  const { trackingCode } = useStepper();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(
    openToCalender ? false : undefined
  );

  const { userTrackingMutation } = useUserEvent();

  const handleUserEvent = (element, fieldValue) => {
    if (element?.target?.value || fieldValue || typeof value !== "string") {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: element?.target?.name || name || "",
          field_value:
            element?.target?.value?.toString() || fieldValue || value || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  const currentDate = new Date();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        key={key}
        label={label}
        className={`${styleClass} mt-18`}
        sx={{ width: "100%" }}
        value={value}
        open={isDatePickerOpen}
        disabled={disabled}
        defaultValue={null}
        minDate={minDate || moment(`${currentDate.getFullYear() - 100}-01-01`)}
        maxDate={maxDate || moment(`${currentDate.getFullYear() - 16}-12-31`)}
        onChange={handleChangeValue}
        onOpen={handleUserEvent}
        onAccept={(value) => {
          handleUserEvent(null, moment(value).format("YYYY-MM-DD"));
          openToCalender ? setIsDatePickerOpen(!isDatePickerOpen) : null;
        }}
        inputFormat="DD-MM-YYYY"
        openTo={openMonth ? "day" : "year"}
        mask="__-__-____"
        DialogProps={{
          className: "date-picker-dialog",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            id={id}
            data-testid={id}
            name={name}
            color="mmm"
            autoComplete="off"
            error={checkFieldError(touched, errors, name)}
            onClick={() => {
              openToCalender ? setIsDatePickerOpen(!isDatePickerOpen) : null;
            }}
            helperText={checkFieldError(touched, errors, name)}
            onFocus={($event) => {
              handleUserEvent($event);
            }}
            onBlur={($event) => {
              handleUserEvent($event);
              handleBlurValue($event);
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DateInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  styleClass: PropTypes.string,
  handleChangeValue: PropTypes.func,
  handleBlurValue: PropTypes.func,
  trackingDetails: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  openMonth: PropTypes.bool,
  openToCalender: PropTypes.bool,
  key: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateInput;
