import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  List,
  ListItem,
  IconButton,
  Tooltip,
  styled,
  FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import FormSelect from "../../../../generic-components/form-select";
import { getPayPeriodName, fixedAmountToDecimal } from "../../../../../helpers";
import FormHeader from "../header/FormHeader";
import { sharedCostFormValidationSchema } from "../../../../../schema";
import { Formik } from "formik";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { updateSharedCostForm } from "../../../../../graphql/mutations/sharedCostAVCAmount";
import { setForm } from "../../../../../reducers/formReducer";
import moment from "moment";
import { getPayPeriod } from "../../../../../graphql/queries/payPeriods";
import Loader from "../../../../generic-components/loader";
import { useToast } from "../../../../../hooks/useToast";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { TRACKING_NAF_OPTIONS, floatRegex } from "../../../../../constants";
import IconInputField from "../../../../generic-components/icon-inputfield";
import TextInput from "../../../../generic-components/input-text";
import SavePlan from "../../../../generic-components/save-plan";
import MaxContributionCalculationForm from "./MaxContributionCalculationForm";
import { calculateMaximumContribution } from "../../../../../utils";

const SharedCostForm = ({ form, organisation }) => {
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const {
    handleNext,
    formValues,
    sharedFormIndex,
    activeStep,
    setFormValues,
    setIsLoadingSave,
    setIsLoadingNext,
    btnClicked,
    setSavedToast,
    setSaveError,
    setErrorToast,
    isLoading,
  } = useStepper();

  const [open, setOpen] = useState(true);
  const [updateSharedCost] = useMutation(updateSharedCostForm);
  const [payPeriod, setPayPeriods] = useState([]);

  const [customError] = useState(null);

  const [getPayPeriodDetails, { loading: payPeriodLoader, error }] =
    useLazyQuery(getPayPeriod, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setPayPeriods(
          data.pay_periods.map((item) => ({
            name: getPayPeriodName(item.period),
            value: getPayPeriodName(item.period),
          }))
        );
      },
    });

  useEffect(() => {
    if (organisation.id)
      getPayPeriodDetails({
        variables: {
          organisation_id: organisation.id,
        },
      });
  }, [organisation]);

  const initialValues = {
    income: formValues.income,
    avcAmount:
      Number(
        formValues.additional_avc_amount
          ? fixedAmountToDecimal(
              Number(formValues.avcAmount) + formValues.additional_avc_amount
            )
          : fixedAmountToDecimal(formValues.avcAmount)
      ) || null,
    hours_per_week: formValues.hours_per_week || null,
    incomeFrequency: formValues.incomeFrequency,
    additional_avc_amount: formValues.additional_avc_amount,
    max_contribution_amount: formValues.max_contribution_amount,
    referral_code: formValues.referral_code || "",
    salarySacrifice: formValues.salarySacrifice || "",
    date_of_birth: formValues.date_of_birth,
    my_hours: formValues.regular_hours
      ? "regular_hours"
      : formValues.uneven_hours === true
      ? "uneven_hours"
      : "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryLoaders = useMemo(() => payPeriodLoader || isLoading);

  const isCustomError = useMemo(() => {
    return !!Object.keys(customError || []).filter((key) => customError[key])
      .length;
  }, [customError]);

  const tooltipContent = (
    <Box
      sx={{
        color: "#ffffff",
        backgroundColor: "#2e2e4f",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", color: "#d0bfff" }}
      >
        Look on your payslip
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Here are some examples of salary sacrifice schemes:
      </Typography>
      <List sx={{ pl: 2, mt: 1 }}>
        <ListItem sx={{ p: 0 }}>• Car allowance</ListItem>
        <ListItem sx={{ p: 0 }}>• Childcare vouchers</ListItem>
        <ListItem sx={{ p: 0 }}>• Cycle to work scheme</ListItem>
        <ListItem sx={{ p: 0 }}>
          • Other employee benefit / perk schemes
        </ListItem>
      </List>
    </Box>
  );

  const TransparentTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "transparent",
      border: "none",
      color: "#000", // Adjust the text color if needed
      boxShadow: "none",
      fontSize: "0.875rem",
    },
  });

  return (
    <>
      <Formik
        validationSchema={sharedCostFormValidationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          if (isCustomError) return;
          setSaveError("");
          setFormValues((prevValues) => ({
            ...prevValues,
            ...values,
            income: parseFloat(values.income),
            max_contribution_amount: values.max_contribution_amount,
            annual_salary: parseInt(values.income),
            uneven_hours: values.my_hours === "uneven_hours",
            regular_hours: values.my_hours === "regular_hours",
            salarySacrifice: parseInt(values.salarySacrifice),
          }));
          if (btnClicked === "save_and_next") {
            setIsLoadingNext(true);
          } else if (btnClicked === "save") {
            setIsLoadingSave(true);
          }

          updateSharedCost({
            variables: {
              id: form.id,
              updated_at: moment().format(),
              total_avc_amount_requested: formValues.additional_avc_amount
                ? Number(values.avcAmount) - formValues.additional_avc_amount
                : parseFloat(values.avcAmount || 0),
              annual_salary: parseFloat(values.income),
              hours_per_week: parseFloat(values.hours_per_week) || 0,
              avc_interval: values.incomeFrequency,
              max_contribution_amount:
                parseFloat(values.max_contribution_amount) || 0,
              previous_amount_added:
                parseFloat(values.previous_amount_added) || 0,
              contribution_amount_updated: !!values.contribution_amount_updated,
              additional_avc_amount: values.additional_avc_amount,
              salarySacrifice: parseFloat(values.salarySacrifice) || 0,
              uneven_hours: values.my_hours === "uneven_hours",
              regular_hours: values.my_hours === "regular_hours",
              step_number:
                btnClicked === "save"
                  ? activeStep + sharedFormIndex
                  : activeStep + sharedFormIndex + 1,
              referral_code: values.referral_code || "",
            },
            onCompleted: (data) => {
              dispatch(setForm(data.update_temp_plans.returning[0]));
              if (btnClicked === "save") {
                setSavedToast(true);
              }
              if (btnClicked === "save_and_next") {
                handleNext();
              }
              setIsLoadingNext(false);
              setIsLoadingSave(false);
            },
            onError: (error) => {
              setIsLoadingNext(false);
              setIsLoadingSave(false);
              setSaveError(error);
              setErrorToast(true);
            },
          });
        }}
      >
        {({
          values,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            {queryLoaders ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <Box className="application-page-container mb-50">
                  {error && showToast(error.message, open, setOpen, "error")}
                  <FormHeader heading={`About your Pay`} />
                  <ScrollToFieldError
                    customError={customError}
                    isCustomError={isCustomError}
                  />
                  <SavePlan isCustomError={isCustomError} />
                  <MaxContributionCalculationForm />
                  <Box className="stepper-field">
                    <IconInputField
                      id="income"
                      name="income"
                      label="How much do you get paid annually? *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      value={values.income}
                      icon={<CurrencyPoundIcon className="input-field-icon" />}
                      isValidValue={(e) => {
                        if (
                          e?.target?.value > 0 &&
                          e?.target?.value?.length <= 9 &&
                          floatRegex.test(parseFloat(e?.target?.value))
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        if (isValidValue) {
                          setFieldValue("income", e?.target?.value);
                        } else {
                          setFieldValue("income", "");
                        }
                      }}
                      handleBlurValue={handleBlur}
                    />

                    <TextInput
                      id="hours_per_week"
                      name="hours_per_week"
                      label="How many hours do you work week *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      value={values.hours_per_week || ""}
                      isValidValue={(e) => {
                        if (
                          e?.target?.value > 0 &&
                          e?.target?.value <= 40 &&
                          floatRegex.test(parseFloat(e?.target?.value))
                        )
                          return true;
                        else return false;
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        if (isValidValue)
                          setFieldValue("hours_per_week", e?.target?.value);
                        else setFieldValue("hours_per_week", "");
                      }}
                      handleBlurValue={handleBlur}
                    />
                    <FormSelect
                      name="incomeFrequency"
                      labelId="incomeFrequencyList"
                      menuItems={payPeriod}
                      label="How often do you get paid? *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                    />
                    <Stack direction="column" spacing={2} marginTop="10px">
                      <Typography variant="h6" className="gutterbottom">
                        My hours:
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={values.my_hours}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (val === "regular_hours") {
                              setFieldValue("salarySacrifice", "");
                            }
                            setFieldValue("my_hours", e.target.value);
                          }}
                          aria-label="My hours"
                          name="my_hours"
                        >
                          <FormControlLabel
                            value="regular_hours"
                            control={<Radio />}
                            label={
                              <Box display="flex" flexDirection="column">
                                <Typography>are regular most weeks</Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  I work either full time or part time.
                                </Typography>
                              </Box>
                            }
                            sx={{ mb: 2 }}
                          />
                          <FormControlLabel
                            value="uneven_hours"
                            control={<Radio />}
                            label={
                              <Box display="flex" flexDirection="column">
                                <Typography>
                                  can change through the year
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  I work uneven hours e.g. as a Term Time worker
                                </Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                        {touched.my_hours && errors.my_hours && (
                          <FormHelperText error={true}>
                            {errors.my_hours}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                    <Box mt={3}>
                      <Typography variant="h6">
                        Total amount of other Salary Sacrifice
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        This is the amount you are paying into other salary
                        sacrifice schemes e.g. cycle to work
                        <span>
                          <TransparentTooltip title={tooltipContent}>
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </TransparentTooltip>
                        </span>
                      </Typography>
                      <IconInputField
                        id="salarySacrifice"
                        name="salarySacrifice"
                        trackingDetails={TRACKING_NAF_OPTIONS}
                        disabled={
                          isEmpty(values.my_hours) ||
                          values.my_hours === "regular_hours"
                        }
                        label={`Total amount of other Salary Sacrifice`}
                        value={values.salarySacrifice}
                        icon={
                          <CurrencyPoundIcon className="input-field-icon" />
                        }
                        isValidValue={(e) => {
                          if (
                            e?.target?.value > 0 &&
                            e?.target?.value?.length <= 9 &&
                            floatRegex.test(parseFloat(e?.target?.value)) &&
                            calculateMaximumContribution({
                              ...values,
                              salarySacrifice: e?.target?.value,
                            }) > 0
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        handleChangeValue={(e, isValidValue) => {
                          if (isValidValue) {
                            setFieldValue("salarySacrifice", e.target.value);
                          } else {
                            setFieldValue("salarySacrifice", "");
                          }
                        }}
                        handleBlurValue={handleBlur}
                      />
                    </Box>
                  </Box>
                </Box>
                <FooterButtons />
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
    organisation: state.organisation.organisation,
    form: state.form.form,
  };
};

SharedCostForm.propTypes = {
  form: PropTypes.object,
  organisation: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(SharedCostForm);
