import { Box, Typography, FormHelperText } from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import SavePlan from "../../../../generic-components/save-plan";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import { getInvestmentOptions } from "../../../../../graphql/queries/investmentOptions";
import MyFundOptions from "./MyFundSelection";
import { MyInvestmentFundSelectionSchema } from "../../../../../schema";

const InvestmentChoiceFundSelection = ({ form }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
  } = useStepper();

  const dispatch = useDispatch();
  const [investmentChoicesOptions, setInvestmentChoicesOptions] = useState([]);

  const initialValues = {
    previousEmployerPlan: formValues.previousEmployerPlan,
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    investmentChoices: formValues.investmentChoices,
  };
  const [getInvestmentChoicesOptions] = useLazyQuery(getInvestmentOptions, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setInvestmentChoicesOptions(
        data.investment_options.map((choice) => ({
          name: choice.display_name,
          value: choice.id,
          lifestyle: choice.lifestyle,
        }))
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formValues.pensionFund && formValues.provider) {
      getInvestmentChoicesOptions({
        variables: {
          pensionId: formValues.pensionFund,
          providerId: formValues.provider,
        },
      });
    }
  }, [formValues.pensionFund, formValues.provider]);

  const [updateFunds] = useMutation(updateFundDetailOnly);

  const saveAction = (data) => {
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(false);
      handleNext();
    } else {
      setIsLoadingSave(false);
      setSavedToast(true);
    }

    dispatch(setForm(data.update_temp_plans.returning[0]));
  };

  const saveError = (error) => {
    setIsLoadingSave(false);
    setSaveError(error);
    setIsLoadingNext(false);
    setErrorToast(true);
  };

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: formValues.pensionFund,
        provider_id: formValues.provider,
        having_previous_employer_plan:
          formValues.previousEmployerPlan === "Yes",
        step_number: btnClicked === "save" ? activeStep + 7 : activeStep + 8,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        saveAction(data);
      },
      onError: (error) => {
        saveError(error);
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MyInvestmentFundSelectionSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const { handleSubmit, errors, touched } = formik;

  const handleNingiChange = () => {
    console.log("handleNingiChange");
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="investment-choices-step-2">
          <ScrollToFieldError />
          <SavePlan />
          <Box className="application-page-container">
            <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
              <Typography>
                <strong>Investment Choices</strong>
              </Typography>
            </Box>
            <Box sx={{ p: 3, bgcolor: "#FEF9FF" }}>
              <div className="img-container">
                <img
                  className="logo-dark"
                  src={pensionFundIcon}
                  alt="logo-dark"
                />
              </div>
              <Typography className="mt-30 heading">
                <strong>Select which funds your money is invested into.</strong>
              </Typography>
              <Typography sx={{ fontSize: "15px", mt: 1 }}>
                Now you can choose exactly where your money should be invested.
              </Typography>
            </Box>
            <MyFundOptions
              investmentChoicesOptions={investmentChoicesOptions}
              handleNingiChange={handleNingiChange}
            />
            {touched.investmentChoices && errors.investmentChoices && (
              <FormHelperText error>{errors.investmentChoices}</FormHelperText>
            )}
          </Box>
          <FooterButtons />
          <Typography sx={{ fontSize: "15px", mt: 1 }}>
            *There is a default fund set. Please note that this is not a
            recommendation and you should choose fund options to suit your
            needs.
          </Typography>
        </Box>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
  };
};

InvestmentChoiceFundSelection.propTypes = {
  form: PropTypes.object,
};

export default connect(mapStateToProps)(InvestmentChoiceFundSelection);
