import {
  Box,
  Typography,
  // useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import Loader from "../../../../generic-components/loader";
import { pensionFundSchema } from "../../../../../schema";
import useStepper from "../../../../../hooks/useStepper";
import { useLazyQuery, useMutation } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { getPensionFunds } from "../../../../../graphql/queries/pensionFunds";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import SavePlan from "../../../../generic-components/save-plan";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { findItemBySelectedValue } from "../../../../../helpers";

const PensionFund = ({ form, pensionName }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    personalDetails,
    setErrorToast,
  } = useStepper();

  const dispatch = useDispatch();
  const initialValues = {
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
  };

  const [pensionFunds, setPensionFunds] = useState([]);
  const [updateFunds] = useMutation(updateFundDetailOnly);
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleError = (error) => {
    setSaveError(error);
    setErrorToast(true);
  };

  const handleCompleteAction = (data, setData) => {
    const modifiedData =
      data?.map((entry) => ({
        name: entry.name,
        value: entry.id,
      })) || [];

    setData(modifiedData);
  };

  const [getPensionFundList, { loading: fundsLoading }] = useLazyQuery(
    getPensionFunds,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        handleCompleteAction(data.pension_funds, setPensionFunds);
      },
      onError: handleError,
    }
  );

  useEffect(() => {
    getPensionFundList();
  }, []);

  const createHandler = (type) => async (input) => {
    try {
      setSaveError("");

      if (type === "user") {
        const { name, checked } = input?.target || {};
        const { fieldName } = findItemBySelectedValue(name);

        await userTrackingMutation({
          variables: {
            ...TRACKING_NAF_OPTIONS,
            field_name: fieldName || "",
            field_value: checked
              ? `Select - ${fieldName}`
              : `Unselect - ${fieldName}`,
            avc_track_code: trackingCode,
          },
        });
      } else if (type === "update") {
        setFormValues((prevValues) => ({ ...prevValues, ...input }));
        const isNextAction = btnClicked === "save_and_next";

        if (isNextAction) {
          setIsLoadingNext(true);
        } else {
          setIsLoadingSave(true);
        }

        await updateFunds({
          variables: {
            id: form.id,
            pension_fund_id: input.pensionFund,
            step_number:
              btnClicked === "save" ? activeStep + 3 : activeStep + 4,
            updated_at: moment().format(),
          },
          onCompleted: (data) => {
            if (isNextAction) {
              setIsLoadingNext(false);
              handleNext();
            } else {
              setIsLoadingSave(false);
              setSavedToast(true);
            }
            dispatch(setForm(data.update_temp_plans.returning[0]));
          },
        });
      }
    } catch (error) {
      setIsLoadingSave(false);
      setSaveError(error);
      setIsLoadingNext(false);
      setErrorToast(true);
    }
  };

  const handleUserEvent = createHandler("user");
  const handleUpdate = createHandler("update");

  console.log({ pensionName });

  return (
    <Formik
      validationSchema={pensionFundSchema}
      initialValues={initialValues}
      onSubmit={handleUpdate}
    >
      {({ handleSubmit, touched, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Box className="investment-choices-step-2">
            {fundsLoading ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <ScrollToFieldError />
                <SavePlan />
                <Box className="application-page-container">
                  <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
                    <Typography>
                      <strong>About your pension</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, bgcolor: "#FEF9FF" }}>
                    <div className="img-container">
                      <img
                        className="logo-dark"
                        src={pensionFundIcon}
                        alt="logo-dark"
                      />
                    </div>
                    <Typography className="mt-30 heading">
                      <strong>Select your Pension Fund</strong>
                    </Typography>
                    <Typography sx={{ fontSize: "15px", mt: 1 }}>
                      Your Employer has more than one Local Government Pension
                      Fund.
                    </Typography>
                    <Typography sx={{ fontSize: "15px", mt: 1 }}>
                      Choose the one that administers your Local Government
                      Pension Scheme.
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "10px", mt: 1 }}>
                    Ask your Employer if you're not sure
                  </Typography>
                  <FormControl component="fieldset" sx={{ mt: 2 }}>
                    <FormLabel component="legend" sx={{ mt: 2 }}>
                      Which is your Pension Fund?
                    </FormLabel>
                    <RadioGroup
                      name="pensionFund"
                      value={formValues.pensionFund?.toString() || ""}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        handleUserEvent(event);
                        setFieldValue("pensionFund", Number(selectedValue));
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          pensionFund: selectedValue,
                        }));
                      }}
                    >
                      {pensionFunds.map((fund) => (
                        <FormControlLabel
                          key={fund.value}
                          value={fund.value.toString()}
                          control={
                            <Radio
                              sx={{
                                color: "#2C2C2C",
                                "&.Mui-checked": {
                                  color: "#2C2C2C",
                                },
                              }}
                            />
                          }
                          label={fund.name}
                        />
                      ))}
                    </RadioGroup>
                    {touched.pensionFund && errors.pensionFund && (
                      <FormHelperText error>
                        {errors.pensionFund}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FooterButtons />
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    // formSharedTitle: state.multiForm.formSharedTitle,
    pensionName: state.multiForm.pensionName,
  };
};

PensionFund.propTypes = {
  form: PropTypes.object,
  // formSharedTitle: PropTypes.string,
  pensionName: PropTypes.string,
};

export default connect(mapStateToProps)(PensionFund);
