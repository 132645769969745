import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import Loader from "../../../../generic-components/loader";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import FooterButtons from "../footer/FooterButtons";
import { getProviders } from "../../../../../graphql/queries/providers";
import useStepper from "../../../../../hooks/useStepper";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import { Link } from "react-router-dom";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { provider } from "../../../../../schema";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { findItemBySelectedValue } from "../../../../../helpers";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";

const ProviderSelection = ({ form }) => {
  const {
    setSaveError,
    setFormValues,
    formValues,
    btnClicked,
    setErrorToast,
    activeStep,
    handleNext,
    setSavedToast,
    setIsLoadingNext,
    setIsLoadingSave,
  } = useStepper();

  const [providers, setProviders] = useState([]);
  const [updateFunds] = useMutation(updateFundDetailOnly);
  const dispatch = useDispatch();
  const [getProvidersList, { loading: providersLoading }] = useLazyQuery(
    getProviders,
    {
      variables: { id: formValues.organisation_id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const modifiedData =
          data.providers?.map((provider) => ({
            name: provider.name,
            value: provider.id,
            more_information_url: provider.more_information_url,
          })) || [];
        setProviders(modifiedData);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  useEffect(() => {
    getProvidersList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const createHandler = (type) => async (input) => {
    try {
      setSaveError("");

      if (type === "user") {
        const { name, checked } = input?.target || {};
        const { fieldName } = findItemBySelectedValue(name);

        await userTrackingMutation({
          variables: {
            ...TRACKING_NAF_OPTIONS,
            field_name: fieldName || "",
            field_value: checked
              ? `Select - ${fieldName}`
              : `Unselect - ${fieldName}`,
            avc_track_code: trackingCode,
          },
        });
      } else if (type === "update") {
        setFormValues((prevValues) => ({
          ...prevValues,
          provider: input.provider,
        }));

        if (btnClicked === "save_and_next") {
          setIsLoadingNext(true);
        } else {
          setIsLoadingSave(true);
        }

        await updateFunds({
          variables: {
            id: form.id,
            provider_id: input.provider,
            step_number:
              btnClicked === "save" ? activeStep + 4 : activeStep + 5,
            updated_at: moment().format(),
          },
          onCompleted: (data) => {
            if (btnClicked === "save_and_next") {
              setIsLoadingNext(false);
              handleNext();
            } else {
              setIsLoadingSave(false);
              setSavedToast(true);
            }
            dispatch(setForm(data.update_temp_plans.returning[0]));
          },
          onError: (error) => {
            setIsLoadingSave(false);
            setSaveError(error);
            setIsLoadingNext(false);
            setErrorToast(true);
          },
        });
      }
    } catch (error) {
      setIsLoadingSave(false);
      setSaveError(error);
      setIsLoadingNext(false);
      setErrorToast(true);
    }
  };

  const handleUserEvent = createHandler("user");
  const handleUpdate = createHandler("update");

  return (
    <Formik
      validationSchema={provider}
      initialValues={{ provider: formValues.provider }}
      onSubmit={handleUpdate}
    >
      {({ handleSubmit, values, setFieldValue, touched, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box className="provider-selection">
            {providersLoading ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <ScrollToFieldError />
                <Box className="application-page-container">
                  <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
                    <Typography>
                      <strong>About your pension</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, bgcolor: "#FEF9FF" }}>
                    <div className="img-container">
                      <img
                        className="logo-dark"
                        src={pensionFundIcon}
                        alt="logo-dark"
                      />
                    </div>
                    <Typography className="mt-30 heading">
                      <strong>Select your AVC Provider</strong>
                    </Typography>
                    <Typography sx={{ fontSize: "15px", mt: 1 }}>
                      You have a choice of AVC providers to invest your money
                      through.
                    </Typography>
                  </Box>
                  <FormControl component="fieldset" sx={{ mt: 2 }}>
                    <FormLabel component="legend" sx={{ mt: 2 }}>
                      Which Provider would you like to choose?
                    </FormLabel>
                    <RadioGroup
                      name="provider"
                      value={values.provider?.toString() || ""}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        handleUserEvent(event);
                        setFieldValue("provider", Number(selectedValue));
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          provider: Number(selectedValue),
                        }));
                      }}
                    >
                      {providers.map((provider) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          key={provider.value}
                        >
                          <FormControlLabel
                            key={provider.value}
                            value={provider.value.toString()}
                            control={
                              <Radio
                                sx={{
                                  color: "#2C2C2C",
                                  "&.Mui-checked": {
                                    color: "#2C2C2C",
                                  },
                                }}
                              />
                            }
                            label={provider.name}
                          />
                          <Link
                            style={{ color: "#C06DF3" }}
                            to={`${provider?.more_information_url}`}
                          >
                            Learn More
                          </Link>
                        </Box>
                      ))}
                    </RadioGroup>
                    {touched.provider && errors.provider && (
                      <FormHelperText error>
                        {errors.pensionFund}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FooterButtons />
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
  };
};

ProviderSelection.propTypes = {
  form: PropTypes.object,
};

export default connect(mapStateToProps)(ProviderSelection);
