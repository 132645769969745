import { gql } from "@apollo/client";

export const getProviders = gql`
  query getProviders($id: Int!) {
    providers(
      where: { organisation_providers: { organisation_id: { _eq: $id } } }
    ) {
      id
      name
      more_information_url
    }
  }
`;
