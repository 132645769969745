/* eslint-disable react/prop-types */
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import { deletePlan } from "../../../../graphql/mutations/deletePlan";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import useStepper from "../../../../hooks/useStepper";
import moment from "moment";
import { logoutUser } from "../../../../graphql/mutations/logoutUser";
import { TRACKING_HEADER_OPTIONS } from "../../../../constants";
import LoadingButton from "../../../generic-components/button";
import { saveTempPlan } from "../../../../graphql/mutations/saveTempPlan";
import {
  createInvestmentChoice,
  deleteInvestmentChoice,
} from "../../../../graphql/mutations/investmentChoice";
import { updateUserInfo } from "../../../../graphql/mutations/personalDetails";
import { legacyAppRedirection } from "../../../../helpers";
import { YesorNoToBoolean } from "../../../../helpers";

const SaveChangesAlert = ({
  handleClosedialog,
  opendialog,
  form,
  tempPlan,
  removeCookies,
}) => {
  const [deleteForm, { loading: isLoading }] = useMutation(deletePlan);
  const [updateApplication, { loading: saveLoading }] =
    useMutation(saveTempPlan);
  const [updateUserDetails, { loading: updateLoading }] =
    useMutation(updateUserInfo);
  const [tempInvestmentChoice] = useMutation(createInvestmentChoice);
  const [deleteTempInvestment] = useMutation(deleteInvestmentChoice);
  const [logout] = useMutation(logoutUser);
  const {
    setSaveError,
    setErrorToast,
    activeStep,
    sharedFormIndex,
    personalDetails,
  } = useStepper();

  const handleLogout = () => {
    logout({
      onCompleted: (data) => {
        removeCookies();
        window.location.href = data?.destroy_session?.logout_url;
      },
      onError: (error) => {
        if (error.message === "Token has expired") {
          removeCookies();
          handleClosedialog();
          legacyAppRedirection(personalDetails?.sub_domain, "/");
        } else {
          setSaveError(error);
          setErrorToast(true);
        }
      },
    });
  };

  const deleteTempRecord = () => {
    if (form.id) {
      deleteForm({
        variables: {
          id: form.id,
        },
        onCompleted: () => handleLogout(),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else handleLogout();
  };

  const createTempInvestment = () => {
    const createInvestmentChoices = tempPlan.investmentChoices.map((choice) => {
      if (choice.name && form.id && choice.percentage) {
        return {
          investment_option_id: choice.name,
          temp_plan_id: form.id,
          specialist_value: choice.percentage,
          default_option: "true",
          created_at: moment().format(),
          updated_at: moment().format(),
        };
      }
    });

    if (
      Array.isArray(createInvestmentChoices) &&
      createInvestmentChoices.length &&
      createInvestmentChoices.filter(Boolean).length
    ) {
      tempInvestmentChoice({
        variables: {
          investmentOptions: createInvestmentChoices.filter(Boolean),
        },
        onCompleted: () => handleLogout(),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else handleLogout();
  };

  const handleInvestmentChoices = () => {
    if (
      Array.isArray(form.investmentChoices) &&
      form.investmentChoices.length &&
      !tempPlan.investmentChoices.length
    ) {
      deleteTempInvestment({
        variables: {
          temp_plan_id: form.id,
        },
        onCompleted: () => handleLogout(),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else if (
      Array.isArray(tempPlan.investmentChoices) &&
      tempPlan.investmentChoices.length
    ) {
      deleteTempInvestment({
        variables: {
          temp_plan_id: form.id,
        },
        onCompleted: () => createTempInvestment(),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else handleLogout();
  };

  const getAVCAmount = () => {
    let avcAmount;
    if (activeStep === 1 && sharedFormIndex === 1) {
      avcAmount = tempPlan.additional_avc_amount
        ? Number(tempPlan.avcAmount) - tempPlan.additional_avc_amount
        : tempPlan.avcAmount;
    } else avcAmount = tempPlan.avcAmount;
    return avcAmount || null;
  };

  const checkMpaaDate = () => {
    return tempPlan.triggeredTheMpaa && tempPlan.MPAAdate
      ? tempPlan.MPAAdate
      : null;
  };

  const checkAccessedDate = () => {
    return tempPlan.accessedPensionBtn && tempPlan.flexiblyAccessedDate
      ? tempPlan.flexiblyAccessedDate
      : null;
  };

  const handleSave = async () => {
    const employeeDetails = {
      title: tempPlan.title,
      gender: tempPlan.gender,
      employee_number: tempPlan.employee_number?.trim(),
      delivery_address1: tempPlan.address1,
      delivery_address2: tempPlan.address2,
      delivery_county: tempPlan.county,
      date_of_birth:
        tempPlan.date_of_birth === "Invalid date"
          ? null
          : tempPlan.date_of_birth,
      email: personalDetails.email,
      first_name: tempPlan.first_name,
      last_name: tempPlan.last_name,
      mobile_number: tempPlan.mobile_number,
      ni_number: tempPlan.ni_number,
      delivery_postcode: tempPlan.postcode,
      delivery_town: tempPlan.town,
      telephone_number: tempPlan.mobile_number,
    };

    updateUserDetails({
      variables: employeeDetails,
      onCompleted: () => {
        if (!form.id) {
          setSaveError({
            message: "Temp plan not found. Please refresh screen.",
            overRideCustom: true,
          });
          setErrorToast(true);
          return;
        }

        updateApplication({
          variables: {
            ...employeeDetails,
            id: form.id,
            step_number: activeStep,
            referral_code: tempPlan.referral_code,
            total_avc_amount_requested: getAVCAmount(),
            annual_salary: parseFloat(tempPlan.income),
            hours_per_week: tempPlan.hours_per_week || null,
            max_contribution_amount: tempPlan.max_contribution_amount || null,
            previous_amount_added: tempPlan.previous_amount_added || null,
            avc_interval: tempPlan.incomeFrequency,
            additional_avc_amount: tempPlan.additional_avc_amount,
            terms_acknowledged: tempPlan.termCondition,
            signatures: tempPlan.signature,
            prudential_declaration: tempPlan.prudentialConsent,
            nhs_pension_scheme_benefits: tempPlan.pensionSchemeBenefits,
            flexibly_accessed_any_pensions: tempPlan.accessedPensionBtn,
            lifestyling_option: tempPlan.lifestyleCheckbox,
            creation_reason_id:
              tempPlan.planReason === -1 ? 0 : tempPlan.planReason,
            creation_reason_other: tempPlan.otherReason,
            pension_fund_id: tempPlan.pensionFund || null,
            provider_id: tempPlan.provider || null,
            pension_age: tempPlan.pensionAge || null,
            retirement_age: parseInt(tempPlan.retirementAge, 10) || null,
            choosen_relevant_investment_option: tempPlan.RnUCheckbox || false,
            having_previous_employer_plan: YesorNoToBoolean(
              tempPlan.previousEmployerPlan || false
            ),
            combining_with_this_employer:
              tempPlan.combiningWithThisEmployer || false,
            keep_separate_with_employer:
              tempPlan.keepSeparateWithEmployer || false,
            triggered_the_mpaa: tempPlan.triggeredTheMpaa || false,
            mpaa_date: checkMpaaDate(),
            flexibly_accessed_date: checkAccessedDate(),
            investment_advice: tempPlan.confirmCheckbox,
            updated_at: moment().format(),
          },
          onCompleted: () => handleInvestmentChoices(),
          onError: (error) => {
            setSaveError(error);
            setErrorToast(true);
          },
        });
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  return (
    <Box>
      <Dialog
        open={opendialog}
        onClose={handleClosedialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="SaveChangesAlert modal-background"
      >
        <DialogTitle className="alert_heading">
          Are you sure you want to leave?
        </DialogTitle>
        <Box className="alert-message-alignment">
          <DialogContent className="alert_message_container">
            <DialogContentText className="alert_message">
              You are about to leave this form without saving your progress.
              Leaving without saving will mean this progress will be lost.
            </DialogContentText>
            <DialogContentText className="alert_message mt-12">
              Instead, you can save your application and pick up where you left
              off when you’re ready.
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions className="alert-btn-container">
          <LoadingButton
            buttonTitle="Save"
            loading={saveLoading || updateLoading}
            trackingDetails={TRACKING_HEADER_OPTIONS}
            handleClick={handleSave}
            styleClass="contained-btn btn"
          />
          <LoadingButton
            buttonTitle="Leave without saving"
            loading={isLoading}
            trackingDetails={TRACKING_HEADER_OPTIONS}
            handleClick={deleteTempRecord}
            styleClass="outlined-btn btn"
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    tempPlan: state.tempPlan.tempPlan,
  };
};

SaveChangesAlert.propTypes = {
  form: PropTypes.object,
  removeCookies: PropTypes.func,
  tempPlan: PropTypes.object,
};

export default connect(mapStateToProps)(SaveChangesAlert);
