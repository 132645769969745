import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  Divider,
  // FormControl,
  IconButton,
  // FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { currencyFormatter } from "../../../../../helpers";
import { useEffect, useMemo, useState, useRef } from "react";
import { fixedAmountToDecimal, formatCurrency } from "../../../../../helpers";
import FormHeader from "../header/FormHeader";
import { validationSchema } from "../../../../../schema";
import { FormikProvider, useFormik } from "formik";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setForm } from "../../../../../reducers/formReducer";
import moment from "moment";
import Loader from "../../../../generic-components/loader";
// import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { TRACKING_NAF_OPTIONS, floatRegex } from "../../../../../constants";
import TextInput from "../../../../generic-components/input-text";
// import SavePlan from "../../../../generic-components/save-plan";
import { getTotalPot } from "../../../../../helpers";
import { getTax } from "../../../../../graphql/queries/getTax";
import {
  BarGraphLabel,
  DisclaimerText,
  CustomRadio,
  CustomLabel,
  TransparentTooltip,
  TooltipContent,
  CustomInput,
} from "./AmountContributionComponents";
// import useUserEvent from "../../../../../hooks/useUserEvent";
import { updatedAvcCost } from "../../../../../graphql/mutations/updatedAvcCost";

const arr = [
  "linear-gradient(to right, #7236AE, #391474)",
  "var(--Purple-50, #C06DF3)",
  "var(--Purple-50, #C06DF3)",
  "linear-gradient(175.52deg, #7236AE -6%, #18112F 103.32%)",
];

const AmmountToContribute = ({ form, scheme }) => {
  const dispatch = useDispatch();
  const {
    handleNext,
    formValues,
    sharedFormIndex,
    activeStep,
    setFormValues,
    setIsLoadingSave,
    setIsLoadingNext,
    btnClicked,
    setSavedToast,
    setSaveError,
    setErrorToast,
    isLoading,
    // trackingCode,
    personalDetails,
  } = useStepper();

  // const [open, setOpen] = useState(true);
  const [updateAvcCost] = useMutation(updatedAvcCost);

  // const [payPeriod, setPayPeriods] = useState([]);
  const [customError] = useState(null);
  const [value, setValue] = useState("");
  const [totalPayment, setTotalPayment] = useState(0);

  const inputRef = useRef();

  const [radioOptions, setRadioOptions] = useState([
    {
      label: "5% Contribution",
      value: formValues.max_contribution_amount * 0.05 || 0,
    },
    {
      label: "7% Contribution",
      value: formValues.max_contribution_amount * 0.07 || 0,
    },
    {
      label: "10% Contribution",
      value: formValues.max_contribution_amount * 0.1 || 0,
    },
    { label: "Custom", value: "" }, // Initial placeholder for the fourth value
  ]);

  // const { userTrackingMutation } = useUserEvent();

  // const [getPayPeriodDetails, { loading: payPeriodLoader, error }] =
  //   useLazyQuery(getPayPeriod, {
  //     fetchPolicy: "no-cache",
  //     onCompleted: (data) => {
  //       setPayPeriods(
  //         data.pay_periods.map((item) => ({
  //           name: getPayPeriodName(item.period),
  //           value: getPayPeriodName(item.period),
  //         }))
  //       );
  //     },
  //   });

  const initialValues = {
    income: formValues.income,
    avcAmount:
      Number(
        formValues.additional_avc_amount
          ? fixedAmountToDecimal(
              Number(formValues.avcAmount) + formValues.additional_avc_amount
            )
          : fixedAmountToDecimal(formValues.avcAmount)
      ) || null,
    hours_per_week: formValues.hours_per_week || null,
    incomeFrequency: formValues.incomeFrequency,
    additional_avc_amount: formValues.additional_avc_amount,
    max_contribution_amount: formValues.max_contribution_amount,
    retirementAge: formValues.retirementAge || "",
    avc_amount_selection: formValues.avcAmount || null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (isCustomError) return;
      setSaveError("");
      setFormValues((prevValues) => ({
        ...prevValues,
        ...values,
        income: parseInt(values.income),
        avcAmount: Number(
          values.additional_avc_amount
            ? Number(values.avcAmount) - values.additional_avc_amount
            : values.avcAmount
        ),
        retirementAge: values.retirementAge,
        annual_salary: parseInt(values.income),
      }));
      if (btnClicked === "save_and_next") {
        setIsLoadingNext(true);
      } else if (btnClicked === "save") {
        setIsLoadingSave(true);
      }
      updateAvcCost({
        variables: {
          id: form.id,
          total_avc_amount_requested: parseFloat(values.avcAmount) || 0,
          additional_avc_amount: formValues.additional_avc_amount || 0,
          contribution_amount_updated: !!formValues.contribution_amount_updated,
          previous_amount_added:
            parseFloat(formValues.previous_amount_added) || 0,
          step_number:
            btnClicked === "save"
              ? activeStep + sharedFormIndex
              : activeStep + sharedFormIndex + 1,
          referral_code: values.referral_code || "",
          retirement_age: parseInt(values.retirementAge) ?? 0,
          updated_at: moment().format(),
        },
        onCompleted: (data) => {
          dispatch(setForm(data.update_temp_plans.returning[0]));
          if (btnClicked === "save") {
            setSavedToast(true);
          }
          if (btnClicked === "save_and_next") {
            handleNext();
          }
          setIsLoadingNext(false);
          setIsLoadingSave(false);
        },
        onError: (error) => {
          setIsLoadingNext(false);
          setIsLoadingSave(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    console.log("Touched", touched);
    console.log("Errors", errors);
  }, [errors, touched]);

  const isMaxAmountEqual = useMemo(() => {
    return (
      value === "Custom" &&
      parseFloat(values.avcAmount) ===
        parseFloat(formValues.max_contribution_amount)
    );
  }, [values.avcAmount, formValues]);

  const [getSavings, { data }] = useLazyQuery(getTax, {
    onCompleted: (data) => {
      const incomeTaxSavingSacrifice =
        data?.plan_savings?.income_tax_saving_on_salary_sacrifice || 0;

      const nicSavingSacrifice =
        data?.plan_savings?.monthly_nic_saving_on_salary_sacrifice || 0;

      const total_pay =
        Number(values.avcAmount) +
        incomeTaxSavingSacrifice +
        nicSavingSacrifice;
      console.log("Type Avc Amount", typeof values.avcAmount);
      setTotalPayment(total_pay);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const incomeTaxSavingSacrifice =
    data?.plan_savings?.income_tax_saving_on_salary_sacrifice || 0;

  const nicSavingSacrifice =
    data?.plan_savings?.monthly_nic_saving_on_salary_sacrifice || 0;

  const [income_tax_integer, income_tax_fraction] = currencyFormatter(
    incomeTaxSavingSacrifice
  );

  const [nic_savings_integer, nic_savings_fraction] =
    currencyFormatter(nicSavingSacrifice);

  useEffect(() => {
    if (
      values.retirementAge &&
      scheme.scheme_id &&
      form.annual_salary &&
      form.avc_interval &&
      values.avcAmount
    ) {
      console.log("Inside Text Values", values);
      getSavings({
        variables: {
          annual_salary: form.annual_salary,
          total_avc_amount_requested: values.avcAmount,
          avc_interval: form.avc_interval,
          scheme_id: scheme.scheme_id,
          add_ni_savings_onto_avc: false,
        },
      });
    }
    console.log("Text Values", values);
  }, [
    values.retirementAge,
    scheme,
    form.annual_salary,
    form.avc_interval,
    values,
    value,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (formValues.avcAmount && formValues.max_contribution_amount) {
    //   const obj = radioOptions.find(
    //     (item) => Number(item.value).toFixed(2) == formValues.avcAmount
    //   );
    //   if (obj) {
    //     setValue(obj?.label ?? "");
    //   } else {
    //     setValue("Custom");
    //   }
    // }
  }, []);

  const queryLoaders = useMemo(() => isLoading);

  const no_of_years =
    parseInt(values.retirementAge || 0) - parseInt(personalDetails.age) || 0;

  const total_contribution_pot = getTotalPot(
    formValues.max_contribution_amount || 0,
    no_of_years,
    0
  );

  const [avc_amount_integer, avc_amount_fraction] = currencyFormatter(
    values.avcAmount
  );

  const [total_amount_integer, total_amount_fraction] = currencyFormatter(
    totalPayment ?? 0
  );

  const isCustomError = useMemo(() => {
    return !!Object.keys(customError || []).filter((key) => customError[key])
      .length;
  }, [customError]);

  const handleValueChange = (event) => {
    console.log("Value====>", event.target.value);
    const max_contr_label = event.target.value;
    const max_contr_value = radioOptions.find(
      (item) => item.label === max_contr_label
    );
    console.log("Radio Option Value", max_contr_value.value);
    if (max_contr_label !== "Custom") {
      setFieldValue("avcAmount", max_contr_value.value);
      setRadioOptions((prevOptions) =>
        prevOptions.map((option, index) =>
          index === 3 ? { ...option, value: "" } : option
        )
      );
    } else {
      inputRef.current?.focus();
    }
    setFieldValue("avc_amount_selection", max_contr_label);
    setValue(event.target.value);
  };

  const updateFourthValue = (newValue) => {
    const val = parseFloat(newValue);
    setRadioOptions((prevOptions) =>
      prevOptions.map((option, index) =>
        index === 3 ? { ...option, value: val } : option
      )
    );
    setFieldValue("avcAmount", parseFloat(val));
  };

  const handleChangeCustomInput = (e) => {
    const inputValue = e.target.value;
    // Allow only the input that matches the regex
    console.log("input value", inputValue);
    if (floatRegex.test(inputValue) || inputValue === "") {
      const numericalValue = parseFloat(inputValue);
      if (numericalValue > formValues.max_contribution_amount || 0) {
        return;
      }
      updateFourthValue(inputValue);
    }
  };

  const handleKeyDown = (e) => {
    const inputValue = e.target.value;

    // Allow Backspace, Left Arrow, and Right Arrow keys
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight"];
    if (!allowedKeys.includes(e.key) || !floatRegex.test(inputValue + e.key)) {
      e.preventDefault();
    }
  };

  const onTapMaxContribution = () => {
    const amount = formValues.max_contribution_amount ?? 0;
    setValue("Custom");
    updateFourthValue(Number(amount).toFixed(2));
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          {queryLoaders ? (
            <Box className="mt-30">
              <Loader />
            </Box>
          ) : (
            <>
              <Box className="application-page-container mb-50">
                {/* {error && showToast(error.message, open, setOpen, "error")} */}
                <FormHeader heading={`Amount to Contribute`} />
                {/* <ScrollToFieldError
                  customError={customError}
                  isCustomError={isCustomError}
                />
                <SavePlan isCustomError={isCustomError} /> */}
                <Box
                  className="stepper-field"
                  sx={{ width: "100%", maxWidth: "100% !important" }}
                >
                  <Stack direction="column" spacing={1} className="mt-18">
                    <Typography className="stepper-content">
                      What age do you plan to retire or start taking your
                      pension?
                    </Typography>
                    <TextInput
                      id="retirementAge"
                      name="retirementAge"
                      label="Retirement Age *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      value={values.retirementAge}
                      handleChangeValue={handleChange}
                      handleBlurValue={handleBlur}
                      type="number"
                      styleClass="stepper-field"
                    />
                    {/* {touched.retirement_age && errors.retirement_age && (
                      <FormHelperText error={true}>
                        {errors.retirement_age}
                      </FormHelperText>
                    )} */}
                  </Stack>
                  <Typography className="mt-18 stepper-content">
                    <strong>
                      How much do you want to pay into your AVC plan?
                    </strong>
                  </Typography>
                  <Typography className="mt-2 stepper-content" marginTop="10px">
                    This is the amount from your{" "}
                    {formValues?.incomeFrequency.toLowerCase() ?? ""} pay
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                      marginBottom="10px"
                      alignItems="flex-end"
                    >
                      {isMaxAmountEqual && (
                        <Typography variant="body2" color="red">
                          Already at max
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="mt-5"
                        onClick={onTapMaxContribution}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <span>
                          <TransparentTooltip title={TooltipContent}>
                            <IconButton
                              sx={{
                                padding: 0,
                                marginBottom: "2px",
                                marginRight: "5px",
                              }}
                            >
                              <InfoIcon sx={{ fontSize: "15px" }} />
                            </IconButton>
                          </TransparentTooltip>
                        </span>
                        Set to Max
                        <Typography
                          variant="body2"
                          component="span"
                          marginLeft="5px"
                        >
                          {formatCurrency(
                            formValues.max_contribution_amount ?? 0
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    paddingLeft="10px"
                    className="mt-18"
                  >
                    <RadioGroup
                      row
                      value={value}
                      onChange={handleValueChange}
                      sx={{ width: "100%", justifyContent: "space-between" }}
                      aria-label="Avc Amount Selection"
                      name="avc_amount_selection"
                    >
                      {radioOptions.map((item, index) => {
                        // console.log("Velue", value);
                        if (index === 3) {
                          if (value === "Custom") {
                            return (
                              <Box
                                key={item.label}
                                sx={{
                                  alignItems: "center",
                                  padding: "4px 0px",
                                  border: "1px solid #7236AE",
                                  borderRadius: "5px",
                                  width: "160px",
                                  display: "flex",
                                  overflow: "hidden",
                                  height: "40px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CustomRadio checked />
                                <Typography
                                  variant="body2"
                                  fontSize="18px"
                                  fontWeight="bold"
                                  color="#7236AE"
                                  marginRight="2px"
                                  marginTop="4px"
                                >
                                  £
                                </Typography>
                                <CustomInput
                                  type="number"
                                  ref={inputRef}
                                  placeholder=""
                                  value={item.value}
                                  onChange={handleChangeCustomInput}
                                  handleKeyDown={handleKeyDown}
                                />
                              </Box>
                            );
                          } else {
                            return (
                              <CustomLabel
                                key={item.label}
                                control={<CustomRadio />}
                                label="Custom"
                                value="Custom"
                                checked={value === "Custom"}
                              />
                            );
                          }
                        } else {
                          return (
                            <CustomLabel
                              key={item.label}
                              control={<CustomRadio />}
                              label={formatCurrency(item.value)}
                              value={item.label}
                              checked={value === item.label}
                            />
                          );
                        }
                      })}
                    </RadioGroup>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingLeft="10px"
                    paddingBottom="20px"
                    className="mt-10"
                    sx={{
                      backgroundColor:
                        values.retirementAge && !value
                          ? "#F2F2F7"
                          : "transparent",
                      height: "290px",
                      position: "relative",
                    }}
                  >
                    {!value && !values.retirementAge && (
                      <Box
                        display="flex"
                        width="100%"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                      >
                        <p
                          style={{
                            margin: 0,
                            textAlign: "center",
                            fontSize: "12px",
                            color: "#7236AE",
                          }}
                        >
                          Shows how much your pot
                          <br />
                          could be worth at retirement.
                        </p>
                      </Box>
                    )}
                    {values.retirementAge && !value && (
                      <Box
                        sx={{
                          position: "absolute", // Position absolutely within the parent Box
                          bottom: 2,
                          left: 0,
                          paddingLeft: "10px", // Add padding if needed
                          fontSize: "10px",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            textAlign: "center",
                            fontSize: "10px",
                          }}
                        >
                          Estimated value
                          <br />
                          at retirement *
                        </p>
                      </Box>
                    )}
                    {radioOptions.map((item, index) => {
                      const fp = getTotalPot(item.value, no_of_years, 0);
                      const progressPercentage = Math.min(
                        (fp / total_contribution_pot) * 100,
                        100
                      );
                      // console.log(
                      //   "Total Pot Contribution",
                      //   total_contribution_pot
                      // );
                      // console.log("Item Value", progressPercentage);
                      return (
                        <Box
                          key={`${item.label}-${index}`}
                          sx={{
                            backgroundColor: (() => {
                              // If neither value nor retirement_age is defined, and index is not 3
                              if (
                                !value &&
                                !values.retirementAge &&
                                index != 3
                              ) {
                                return "#F2F2F7";
                              }
                              // If the value matches the item.label
                              if (value === item.label) {
                                return "#F2F2F7";
                              }
                              // Default to transparent
                              return "transparent";
                            })(),
                            height: "280px",
                            width: "160px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {values.retirementAge && (
                            <Typography
                              className="stepper-content"
                              textAlign="center"
                            >
                              <strong>{formatCurrency(fp)}</strong>
                            </Typography>
                          )}
                          <Box
                            sx={{
                              height: `${progressPercentage}%`,
                              background: arr[index],
                              width: "50px",
                              transition: "height 0.3s ease-in-out",
                              marginBottom: "10px",
                            }}
                          />
                          {value === item.label && <BarGraphLabel />}
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* Title */}
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#757575" }}
                    >
                      Amount per month**
                    </Typography>

                    {/* You pay row */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                    >
                      <Box flex={0.35}>
                        <Typography
                          variant="body1"
                          color="#757575"
                          textAlign="end"
                        >
                          You pay
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flex={0.65}
                        alignItems="baseline"
                        paddingLeft="15px"
                      >
                        <Typography variant="body1" color="#757575">
                          <Typography display="inline" fontSize="20px">
                            {avc_amount_integer}
                          </Typography>
                          <Typography
                            display="inline"
                            className="fraction-text"
                          >
                            .{avc_amount_fraction}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>

                    {/* Income Tax Relief row */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                    >
                      <Box flex={0.35}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#0F8F07", fontWeight: "bold" }}
                          textAlign="end"
                        >
                          + Income Tax relief
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="baseline"
                        flex={0.65}
                        paddingLeft="15px"
                      >
                        <Typography color="#0F8F07">
                          <Typography display="inline" fontSize="20px">
                            {income_tax_integer}
                          </Typography>
                          <Typography
                            display="inline"
                            className="fraction-text"
                          >
                            .{income_tax_fraction}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>

                    {/* National Insurance Relief row */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                    >
                      <Box flex={0.35}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#0F8F07", fontWeight: "bold" }}
                          textAlign="end"
                        >
                          + National Insurance relief
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flex={0.65}
                        alignItems="baseline"
                        paddingLeft="15px"
                      >
                        <Typography color="#0F8F07">
                          <Typography display="inline" fontSize="20px">
                            {nic_savings_integer}
                          </Typography>
                          <Typography
                            display="inline"
                            className="fraction-text"
                          >
                            .{nic_savings_fraction}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>

                    {/* Divider */}
                    <Divider sx={{ my: 2, width: "40%" }} />

                    {/* Total added to retirement pot row */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box flex={0.35}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#391474" }}
                          textAlign="end"
                        >
                          Total added to retirement pot
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flex={0.65}
                        alignItems="baseline"
                        paddingLeft="10px"
                      >
                        <Typography color="#0F8F07">
                          <Typography display="inline" fontSize="20px">
                            {total_amount_integer}
                          </Typography>
                          <Typography
                            display="inline"
                            className="fraction-text"
                          >
                            .{total_amount_fraction}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <FooterButtons />
              <DisclaimerText />
            </>
          )}
        </form>
      </FormikProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
    organisation: state.organisation.organisation,
    scheme: state.scheme.scheme,
    form: state.form.form,
  };
};

AmmountToContribute.propTypes = {
  form: PropTypes.object,
  organisation: PropTypes.object,
  formSharedTitle: PropTypes.string,
  scheme: PropTypes.object,
};

export default connect(mapStateToProps)(AmmountToContribute);
