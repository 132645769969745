import {
  Box,
  Stack,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
  Button,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomFormSelect from "../../../../generic-components/form-select";
import useStepper from "../../../../../hooks/useStepper";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { checkFieldError, fixedAmountToDecimal } from "../../../../../helpers";
import { TRACKING_NAF_OPTIONS, integerRegex } from "../../../../../constants";
import CustomTextField from "../../../../generic-components/text-field";
import useUserEvent from "../../../../../hooks/useUserEvent";

const MyFundOptions = ({ investmentChoicesOptions, handleNingiChange }) => {
  const { formValues, trackingCode } = useStepper();

  const { values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();

  const handlePush = (arrayHelpers) => {
    if (
      investmentChoicesOptions.length > 1 &&
      values.investmentChoices.length < investmentChoicesOptions.length
    ) {
      arrayHelpers.push({ name: "", value: "", percentage: "" });
    } else {
      console.log("Nothing Here");
    }
  };

  const calculateTotalFundsPercentage = (value, index) => {
    return values.investmentChoices.reduce(
      (accumulator, currentValue, currentIndex) =>
        accumulator +
        Number(currentIndex === index ? value : currentValue.percentage),
      0
    );
  };

  const handleFundPercentage = (e, setFieldValue, index) => {
    const sum = calculateTotalFundsPercentage(e.target.value, index);

    if (e.target.value < 1 || e.target.value > 100 || sum > 100) {
      setFieldValue(`investmentChoices[${index}].percentage`, "");
    } else if (integerRegex.test(e.target.value)) {
      setFieldValue(`investmentChoices[${index}].percentage`, e.target.value);
    }
  };

  const contributedAmountForAvc = useMemo(() => {
    if (!formValues.additional_avc_amount) {
      return formValues.avcAmount;
    }

    return Number(formValues.avcAmount) + formValues.additional_avc_amount;
  }, [formValues.avcAmount]);

  const { userTrackingMutation } = useUserEvent();

  const handleFundPercentageEvent = (element, index) => {
    const value = values.investmentChoices[index]?.name;
    const data = investmentChoicesOptions?.find((item) => item.value === value);
    const targetValue = element?.target?.value || "";
    const fieldName = data?.name
      ? `${data?.name} - ${String(targetValue)}%`
      : "percentage";
    if (targetValue) {
      userTrackingMutation({
        variables: {
          ...TRACKING_NAF_OPTIONS,
          field_name: fieldName,
          field_value: targetValue ? `${String(targetValue)}%` : `0%`,
          avc_track_code: trackingCode,
        },
      });
    }
  };

  const handleUserEventForAnayltics = (name) => {
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: name,
        field_value: "Clicked",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleEventDelete = (index) => {
    const value = values.investmentChoices[index]?.value;
    const data = investmentChoicesOptions?.find((item) => item.value === value);
    const fieldName = data?.name ? `${data?.name} - Delete` : "Delete";
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName,
        field_value: "Delete",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleAddFund = (arrayHelpers) => (e) => {
    handlePush(arrayHelpers);
    handleNingiChange();
    handleUserEventForAnayltics(e?.target?.innerText || "Add Funds");
  };

  const handleDeleteFund = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);
    handleNingiChange();
    handleEventDelete(index);
  };

  return (
    <Box>
      {investmentChoicesOptions?.length && values.investmentChoices?.length ? (
        <FieldArray
          name="investmentChoices"
          render={(arrayHelpers, index) => (
            <Box key={index}>
              {values?.investmentChoices?.map((field, fieldIndex) => {
                return (
                  <Stack
                    direction="row"
                    spacing={2}
                    className="mt-18"
                    alignItems="start"
                    key={`${fieldIndex}-${index}`}
                  >
                    <CustomFormSelect
                      name="investmentChoices"
                      labelId="providerList"
                      menuItems={[...investmentChoicesOptions]}
                      label="Investment choices *"
                      index={index}
                      subField="name"
                      handleCustomChange={handleNingiChange}
                      trackingDetails={TRACKING_NAF_OPTIONS}
                    />
                    <CustomTextField
                      id={`investmentChoices[${index}].percentage`}
                      name={`investmentChoices[${index}].percentage`}
                      value={values.investmentChoices[index].percentage}
                      variant="outlined"
                      className="mt-18"
                      color="mmm"
                      sx={{
                        width: {
                          xs: "50% !important",
                          sm: "40% !important",
                        },
                      }}
                      onChange={(e) => {
                        handleFundPercentage(e, setFieldValue, index);
                        handleNingiChange();
                      }}
                      onFocus={(e) => {
                        handleFundPercentageEvent(e, index);
                      }}
                      onBlur={(e) => {
                        handleFundPercentageEvent(e, index);
                        handleChange(e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PercentIcon className="input-field-icon" />
                          </InputAdornment>
                        ),
                        min: 0,
                        max: 100,
                      }}
                      error={checkFieldError(
                        touched,
                        errors,
                        "investmentChoices",
                        index,
                        "percentage"
                      )}
                      helperText={checkFieldError(
                        touched,
                        errors,
                        "investmentChoices",
                        index,
                        "percentage"
                      )}
                    />
                    <MuiTextField
                      value={fixedAmountToDecimal(
                        (contributedAmountForAvc / 100) *
                          values?.investmentChoices[index]?.percentage
                      )}
                      variant="outlined"
                      className="mt-18"
                      sx={{ width: { xs: "50%", sm: "40%" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyPoundIcon className="input-field-icon" />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                    {values?.investmentChoices.length > 1 && (
                      <DeleteIcon
                        className="delete-icon mt-30"
                        data-value="Delete"
                        sx={{ fontSize: 30 }}
                        onClick={handleDeleteFund(arrayHelpers, index)}
                      />
                    )}
                  </Stack>
                );
              })}
              <Button onClick={handleAddFund(arrayHelpers)}>Add Fund</Button>
            </Box>
          )}
        />
      ) : (
        <Typography className="imp-text mt-18">
          <strong>
            Sorry! No investment choice is available for this pension fund!
          </strong>
        </Typography>
      )}
    </Box>
  );
};

MyFundOptions.propTypes = {
  investmentChoicesOptions: PropTypes.array,
  handleNingiChange: PropTypes.func,
};

export default MyFundOptions;
