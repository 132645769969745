import {
  Box,
  Typography,
  FormControlLabel,
  IconButton,
  styled,
  Tooltip,
  Radio,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const BarGraphLabel = () => (
  <Box
    sx={{
      marginTop: "auto",
    }}
  >
    <p
      style={{
        margin: 0,
        textAlign: "center",
        fontSize: "10px",
      }}
    >
      Estimated value
      <br />
      at retirement *
    </p>
  </Box>
);

const DisclaimerText = () => {
  return (
    <Box sx={{ padding: 2 }}>
      {/* First Paragraph */}
      <Typography variant="body2" color="black" gutterBottom>
        *Figures are for illustrative purposes only. Estimates aren't
        guaranteed. Tax treatments are based on individual circumstances and may
        be subject to change in the future. Your Shared Cost AVC pot will be
        invested by your AVC provider. This assumes a net assumed growth rate of
        3%.
      </Typography>

      {/* Second Paragraph */}
      <Typography variant="body2" color="black">
        **The estimated savings each payroll is not guaranteed and could vary
        depending on your circumstances and the rate at which you pay Income
        Tax. These calculations are based on Income Tax at 20% (basic rate) and
        40% (higher rate), and National Insurance contributions at 8% (basic
        rate) and 2% (higher rate).
      </Typography>
    </Box>
  );
};

const TooltipContent = (
  <Box
    sx={{
      color: "#ffffff",
      backgroundColor: "#2e2e4f",
      p: 2,
      borderRadius: 1,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{ fontWeight: "bold", color: "white" }}
      textAlign="left"
    >
      <span>
        <IconButton
          sx={{
            padding: 0,
            marginBottom: "2px",
            marginRight: "5px",
          }}
        >
          <InfoIcon sx={{ fontSize: "15px", color: "white" }} />
        </IconButton>
      </span>
      WHY IS THERE A MAXIMUM?
    </Typography>
    <Typography
      variant="body2"
      sx={{ mt: 1, color: "#d0bfff" }}
      textAlign="left"
    >
      We set a maximum to make sure you get paid the National Minimum Wage (NMW)
    </Typography>
    <Typography variant="body2" mt={1} textAlign="left">
      The government sets what your minimum pay can be. Your AVC contribution is
      applied before you're paid, so you need to have enough afterwards to meet
      the NMW.
    </Typography>
    <Typography variant="body2" mt={1} textAlign="left">
      Our calculation is based on your age and other salary sacrifice that
      you’ve told us about on the previous page.
    </Typography>
  </Box>
);

const TransparentTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "transparent",
    border: "none",
    color: "#000", // Adjust the text color if needed
    boxShadow: "none",
    fontSize: "0.875rem",
  },
});

// eslint-disable-next-line no-empty-pattern
const CustomRadio = styled(Radio)(() => ({
  "& .MuiSvgIcon-root": {
    fontSize: 28,
  },
  "&.Mui-checked": {
    color: "#18C10E",
  },
  color: "#18C10E",
}));

// eslint-disable-next-line no-empty-pattern
const CustomLabel = styled(FormControlLabel)(() => ({
  border: "2px solid #7236AE",
  borderRadius: "5px",
  padding: "5px 4px",
  fontWeight: "bold",
  borderWidth: "1px",
  color: "#7236AE",
  width: "160px",
  height: "40px",
  display: "flex",
  marginLeft: 0,
  marginRight: 0,
  gap: "20px",
  justifyContent: "flex-start",
  "& .MuiTypography-root": {
    fontSize: "18px",
    fontWeight: "bold",
  },
  "& .MuiRadio-root": {
    padding: "0 2px",
  },
  "&.Mui-checked": {
    backgroundColor: "rgba(0, 255, 0, 0.1)",
  },
}));

const CustomInput = styled("input")({
  fontSize: "18px",
  color: "#7236AE",
  fontWeight: "bold",
  outline: "none",
  border: "none",
  width: "100%",
  // Style for the placeholder
  "&::placeholder": {
    fontSize: "15px", // Custom font size for the placeholder
    color: "gray", // Custom placeholder color (optional)
  },
});

export {
  BarGraphLabel,
  DisclaimerText,
  TooltipContent,
  TransparentTooltip,
  CustomLabel,
  CustomRadio,
  CustomInput,
};
