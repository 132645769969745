import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useQuery } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import SavePlan from "../../../../generic-components/save-plan";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import investmentChoicesButton from "../../../../../assets/images/investment-choices.png";
import { getPensionFundById } from "../../../../../graphql/queries/pensionFunds";

const OpenFundChoices = ({ form }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
  } = useStepper();
  const dispatch = useDispatch();
  const [isFundGuideOpened, setIsFundGuideOpened] = useState(false);
  const [prudentialLink, setPrudentialLink] = useState("");

  const { loading: linksLoading, error: linksError } = useQuery(
    getPensionFundById,
    {
      variables: {
        id: formValues.pensionFund,
      },
      onCompleted: (data) => {
        const pensionFunds = data.pension_funds.find((fund) => fund);
        setPrudentialLink(
          pensionFunds.prudential_pension_fund_url ||
            "https://www.pru.co.uk/rz/localgov/"
        );
      },
    }
  );

  console.log({ linksLoading, linksError });

  const initialValues = {
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    combiningWithThisEmployer: formValues.combiningWithThisEmployer,
    keepSeparateWithEmployer: formValues.keepSeparateWithEmployer,
    triggeredTheMpaa: formValues.triggeredTheMpaa,
    readFundGuide: false, // New checkbox field
  };
  const [fundGuideError, setFundGuideError] = useState("");

  const [updateFunds] = useMutation(updateFundDetailOnly);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFundGuideClick = () => {
    console.log("Fund guide opened");
    setIsFundGuideOpened(true);
    setFundGuideError("");
    window.open(prudentialLink, "_blank");
  };

  const completeAction = (data) => {
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(false);
      handleNext();
    } else {
      setIsLoadingSave(false);
      setSavedToast(true);
    }

    dispatch(setForm(data.update_temp_plans.returning[0]));
  };

  const errorAction = (error) => {
    setIsLoadingSave(false);
    setSaveError(error);
    setIsLoadingNext(false);
    setErrorToast(true);
  };

  const handleUpdate = (values, { setSubmitting }) => {
    if (!isFundGuideOpened) {
      setFundGuideError("Please open and review the fund guide first");
      setSubmitting(false);
      return;
    }
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    console.log("Submitted Values:", values);
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        combining_with_this_employer:
          values.combiningWithThisEmployer === "Yes",
        keep_separate_with_employer: values.keepSeparateWithEmployer === "Yes",
        triggered_the_mpaa: values.triggeredTheMpaa === "Yes",
        mpaa_date:
          values.triggeredTheMpaa === "Yes" && values.MPAAdate
            ? values.MPAAdate
            : null,
        step_number: btnClicked === "save" ? activeStep + 6 : activeStep + 7,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        completeAction(data);
      },
      onError: (error) => {
        errorAction(error);
      },
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleUpdate}>
      {({ handleSubmit, values, setFieldValue, touched, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box className="investment-choices-step-2">
            <ScrollToFieldError />
            <SavePlan />
            <Box className="application-page-container">
              <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
                <Typography>
                  <strong>Investment Choices</strong>
                </Typography>
              </Box>
              <Box sx={{ p: 3, bgcolor: "#FEF9FF", borderRadius: 3 }}>
                <div className="img-container">
                  <img
                    className="logo-dark"
                    src={pensionFundIcon}
                    alt="logo-dark"
                  />
                </div>
                <Typography className="mt-30 heading">
                  <strong>
                    Understand the investment funds you can choose.
                  </strong>
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  It’s your choice where to invest your money. You can choose
                  one fund, or split the payments across several funds.
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 2 }}>
                  Some funds are riskier than others and also have different
                  levels of potential return. Usually the higher the risk, the
                  higher the potential return. Annual management fees can vary
                  between funds as well.
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 2 }}>
                  You may want to consider what level of risk you’re prepared to
                  take, in relation to how close you are to retirement.
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  Generally, the closer you are to retirement, the lesser the
                  risk you may want to take.
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#391474",
                  color: "white",
                  borderRadius: 3,
                }}
              >
                <Typography sx={{ mb: 3 }}>
                  Read the fund guide to help you choose your investment(s).
                </Typography>
                <Button
                  className="img-container"
                  onClick={handleFundGuideClick}
                >
                  <img
                    className="logo-dark"
                    src={investmentChoicesButton}
                    alt="logo-dark"
                  />
                </Button>
                {fundGuideError && (
                  <Typography
                    sx={{
                      color: "error.main",
                      mt: 1,
                      fontSize: "0.75rem",
                    }}
                  >
                    {fundGuideError}
                  </Typography>
                )}
              </Box>
              {/* Checkbox for confirming the fund guide */}
              <Box sx={{ mt: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.readFundGuide}
                      onChange={(e) =>
                        setFieldValue("readFundGuide", e.target.checked)
                      }
                      disabled={!isFundGuideOpened}
                      sx={{
                        color: "#2C2C2C",
                        "&.Mui-checked": {
                          color: "#2C2C2C",
                        },
                      }}
                    />
                  }
                  label="I have read the fund guide"
                />
                {touched.readFundGuide && errors.readFundGuide && (
                  <Typography
                    sx={{
                      color: "error.main",
                      mt: 0.5,
                      fontSize: "0.75rem",
                    }}
                  >
                    {errors.readFundGuide}
                  </Typography>
                )}
              </Box>
              <FooterButtons />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
  };
};

OpenFundChoices.propTypes = {
  form: PropTypes.object,
};

export default connect(mapStateToProps)(OpenFundChoices);
