import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import SavePlan from "../../../../generic-components/save-plan";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import { getInvestmentOptions } from "../../../../../graphql/queries/investmentOptions";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { findItemBySelectedValue } from "../../../../../helpers";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import Continue from "../../../../../assets/images/continue.png";
import Start from "../../../../../assets/images/start.png";
import { getNingiDetails } from "../../../../../graphql/queries/getningijourneyDetails";
import { startNingiJourneyForInvestment } from "../../../../../graphql/mutations/startNingiJourneyForInvestment";
import NINGI_JOURNEY_STATUS from "../../../../../constants/ningiJourneyStatus";
import MyFundOptions from "./MyFundSelection";
import { investmentChoiceSchemaForFund } from "../../../../../schema";
import {
  createInvestmentChoice,
  deleteInvestmentChoice,
} from "../../../../../graphql/mutations/investmentChoice";

const FundChoices = ({ form }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
    setFullScreenLoading,
  } = useStepper();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const [lifeStyleChecked, setLifeStyleChecked] = useState(false);
  const [userWithNingiRecommendation, setUserWithNingiRecommendation] =
    useState([]);
  const [nonLifeStyleFunds, setNonLifeStyleFunds] = useState([]);
  const [
    investmentChoiceLifeStyleOptions,
    setInvestmentChoicesLifeStyleOptions,
  ] = useState([]);
  const [tempInvestmentChoice] = useMutation(createInvestmentChoice);
  const [deleteTempInvestment] = useMutation(deleteInvestmentChoice);
  const initialValues = {
    previousEmployerPlan: formValues.previousEmployerPlan,
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    fundChoices: null,
    investmentChoices: null,
  };
  console.log({ lifeStyleChecked, investmentChoiceLifeStyleOptions });
  const [getInvestmentChoicesOptions] = useLazyQuery(getInvestmentOptions, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const isLifeStyle = data?.investment_options.every(
        (option) => option.lifestyle === true
      );
      setLifeStyleChecked(isLifeStyle);
      const arrInvestmentOptions = data.investment_options.map((choice) => ({
        name: choice.display_name,
        value: choice.id,
        lifestyle: choice.lifestyle,
      }));
      console.log({ arrInvestmentOptions });
      setNonLifeStyleFunds(arrInvestmentOptions);
      setInvestmentChoicesLifeStyleOptions(
        arrInvestmentOptions.filter((item) => item.lifestyle === true)
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });
  const [startJourney] = useMutation(startNingiJourneyForInvestment);

  const { personalDetails } = useStepper();

  const [fetchNingiDetails] = useLazyQuery(getNingiDetails, {
    variables: {
      id: personalDetails.id,
      organisation_id: personalDetails.organisation_id,
    },
    onCompleted: (data) => {
      setUserWithNingiRecommendation(data?.ningi_journeys);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (personalDetails.organisation_id && personalDetails.id) {
      fetchNingiDetails();
    }
  }, [personalDetails.organisation_id, personalDetails.id]);

  useEffect(() => {
    if (formValues.pensionFund && formValues.provider) {
      getInvestmentChoicesOptions({
        variables: {
          pensionId: formValues.pensionFund,
          providerId: formValues.provider,
        },
      });
    }
  }, [formValues.pensionFund, formValues.provider]);

  const [updateFunds] = useMutation(updateFundDetailOnly);

  const createTempInvestment = (investmetnOptionValue) => {
    const payload = {
      investment_option_id: investmetnOptionValue.investmentChoice,
      temp_plan_id: form.id,
      specialist_value: 100,
      default_option: "false",
      created_at: moment().format(),
      updated_at: moment().format(),
    };

    tempInvestmentChoice({
      variables: {
        investmentOptions: { ...payload },
      },
      onCompleted: () => {
        dispatch(
          setForm({ investmentChoices: investmetnOptionValue.investmentChoice })
        );
        setFormValues((prevValues) => ({
          ...prevValues,
          investmentChoices: investmetnOptionValue.investmentChoice,
        }));
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }

    const fundChoices = {
      use_lifestyle_selection:
        values.fundChoices === "use_lifestyle_selection" ? true : undefined,
      select_my_own_funds:
        values.fundChoices === "select_my_own_funds" ? true : undefined,
      fund_by_westminster_wealth:
        values.fundChoices === "fund_by_westminster_wealth" ? true : undefined,
    };

    const filteredChoices = Object.fromEntries(
      Object.entries(fundChoices).filter((value) => value === true)
    );

    console.log({ filteredChoices });

    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: formValues.pensionFund,
        provider_id: formValues.provider,
        having_previous_employer_plan:
          formValues.previousEmployerPlan === "Yes",
        step_number: btnClicked === "save" ? activeStep + 6 : activeStep + 7,
        // add the fund choices selected value
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        if (btnClicked === "save_and_next") {
          setIsLoadingNext(false);
          handleNext();
        } else {
          setIsLoadingSave(false);
          setSavedToast(true);
        }

        dispatch(setForm(data.update_temp_plans.returning[0]));
        deleteTempInvestment({
          variables: {
            temp_plan_id: form.id,
          },
          onCompleted: () => createTempInvestment(values),
          onError: (error) => {
            setSaveError(error);
            setErrorToast(true);
          },
        });
      },
      onError: (error) => {
        setIsLoadingSave(false);
        setSaveError(error);
        setIsLoadingNext(false);
        setErrorToast(true);
      },
    });
  };

  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: investmentChoiceSchemaForFund,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });
  const { handleSubmit, values, setFieldValue, touched, errors } = formik;

  const startNingiJourneyRedirect = async () => {
    setFullScreenLoading(true);
    startJourney({
      variables: {
        redirect_url: `https://dev-auth.my-money-matters.co.uk//redirect-application-form`,
        id: form.id,
      },
      onCompleted: (data) => {
        if (data.startNingiJourney?.journey_url) {
          window.open(data.startNingiJourney?.journey_url, "_blank");
        }
      },
      onError: (error) => {
        setFullScreenLoading(false);

        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const NingiContinue = () => {
    return (
      <>
        <Box sx={{ bgcolor: "#391474", color: "#fff", borderRadius: "10px" }}>
          <Box sx={{ padding: "8%" }}>
            <Typography className=" heading">
              <strong style={{ fontSize: "31px" }}>
                Complete your assessment
              </strong>
            </Typography>
            <Typography sx={{ fontSize: "15px", mt: 1.5 }}>
              <strong>
                {" "}
                The recommended funds will appear here when you have finished
                the form.
              </strong>
            </Typography>
            <Typography sx={{ fontSize: "15px", mt: 3, mb: 3 }}>
              This is an independent service, provided by Westminster Wealth, at
              no cost to you. This is an FCA regulated investment advice service
            </Typography>
            <Button
              className="img-container"
              //   onClick={handleFundGuideClick}
            >
              <img className="logo-dark" src={Continue} alt="logo-dark" />
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const NingiStart = () => {
    return (
      <Box sx={{ bgcolor: "#391474", color: "#fff", borderRadius: "10px" }}>
        <Box sx={{ padding: "8%" }}>
          <Typography className=" heading">
            <strong style={{ fontSize: "31px" }}>
              Recommend the best funds for me
            </strong>
          </Typography>
          <Typography sx={{ fontSize: "15px", mt: 1.5 }}>
            This advice is a 15 minute questionnaire that will assess your
            appetite for risk in relation to your age and retirement date
          </Typography>
          <Typography sx={{ fontSize: "15px", mt: 3, mb: 3 }}>
            It is an independent service, provided by Westminster Wealth, at no
            cost to you. This is an FCA regulated investment advice service.
          </Typography>
          <Typography sx={{ fontSize: "15px", mt: 3, mb: 3 }}>
            To power this assessment we will share information you have already
            told My Money Matters.
          </Typography>
          <Typography sx={{ fontSize: "15px", mt: 3, mb: 3 }}>
            It will open in a new tab
          </Typography>
          <Button className="img-container" onClick={startNingiJourneyRedirect}>
            <img className="logo-dark" src={Start} alt="logo-dark" />
          </Button>
        </Box>
      </Box>
    );
  };

  const FundsRecommended = () => {
    return (
      <Box sx={{ bgcolor: "#391474", color: "#fff", borderRadius: "10px" }}>
        <Box sx={{ padding: "7%" }}>
          <Typography sx={{ fontSize: "15px", mt: 1.5 }}>
            <strong style={{ fontSize: "15px" }}>
              Funds Recommended for me by Webminister Wealth
            </strong>
          </Typography>
          <Typography sx={{ fontSize: "13px", mb: 3 }}>
            FCA regulated investment advice service
          </Typography>
          {/* this all data should be dynamic after ningi */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              background: "#fff",
              color: "#000",
              p: "2%",
              mb: 2,
            }}
          >
            <Typography>Pre-selected very long fund names goes he..</Typography>
            <Typography>25%</Typography>
            <Typography>£ 500</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const SectionBox = () => {
    return (
      <Box className="application-page-container" sx={{ mb: -7 }}>
        <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
          <Typography>
            <strong>Investment Choices</strong>
          </Typography>
        </Box>
        <Box sx={{ p: 3, bgcolor: "#FEF9FF" }}>
          <div className="img-container">
            <img className="logo-dark" src={pensionFundIcon} alt="logo-dark" />
          </div>
          <Typography className="mt-30 heading">
            <strong>Select which funds your money is invested into.</strong>
          </Typography>
          <Typography sx={{ fontSize: "15px", mt: 1 }}>
            Now you can choose exactly where your money should be invested.
          </Typography>
        </Box>
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend" sx={{ mt: 2 }}>
            How would you like to choose your funds?
          </FormLabel>
          <RadioGroup
            name="fundChoices"
            value={values.fundChoices}
            onChange={(event) => {
              const selectedValue = event.target.value;
              console.log("Selected Values", selectedValue);
              handleUserEvent(event);
              setFieldValue("fundChoices", selectedValue);
              setFormValues((prevValues) => ({
                ...prevValues,
                use_lifestyle_selection:
                  selectedValue === "use_lifestyle_selection",
                select_my_own_funds: selectedValue === "select_my_own_funds",
                fund_by_westminster_wealth:
                  selectedValue === "fund_by_westminster_wealth",
              }));
            }}
          >
            <FormControlLabel
              key={1}
              value={"use_lifestyle_selection"}
              control={
                <Radio
                  sx={{
                    color: "#2C2C2C",
                    "&.Mui-checked": {
                      color: "#2C2C2C",
                    },
                  }}
                />
              }
              label={"Use a Lifestyle option"}
            />
            <FormControlLabel
              key={2}
              value={"select_my_own_funds"}
              control={
                <Radio
                  sx={{
                    color: "#2C2C2C",
                    "&.Mui-checked": {
                      color: "#2C2C2C",
                    },
                  }}
                />
              }
              label={"Select my own funds"}
            />
            <FormControlLabel
              key={3}
              value={"fund_by_westminster_wealth"}
              control={
                <Radio
                  sx={{
                    color: "#2C2C2C",
                    "&.Mui-checked": {
                      color: "#2C2C2C",
                    },
                  }}
                />
              }
              label={"Funds recommended for me by Westminister Wealth"}
            />
          </RadioGroup>
          {touched.fundChoices && errors.fundChoices && (
            <FormHelperText error={true}>{errors.fundChoices}</FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  };

  const handleNingiChange = () => {
    console.log("handleNingiChange");
  };
  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="investment-choices-step-2">
          <ScrollToFieldError />
          <SavePlan />
          <SectionBox />
          {console.log({ values })}
          {values.fundChoices === "use_lifestyle_selection" ? (
            <>
              <p>Select a lifestyle option</p>
              {investmentChoiceLifeStyleOptions?.map((item) => (
                <Box
                  key={item}
                  sx={{
                    bgcolor: "#FEF9FF",
                    p: 1,
                  }}
                >
                  <RadioGroup
                    name="investmentChoices"
                    value={values.investmentChoices}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      console.log("Selected Value", selectedValue);
                      handleUserEvent(event); // your custom function
                      setFieldValue("investmentChoices", selectedValue);
                    }}
                  >
                    <FormControlLabel
                      value={`${item.value}`}
                      control={
                        <Radio
                          sx={{
                            color: "#2C2C2C",
                            "&.Mui-checked": {
                              color: "#2C2C2C",
                            },
                          }}
                        />
                      }
                      label={`${item.name}`}
                    />
                  </RadioGroup>
                </Box>
              ))}
              {touched.investmentChoices && errors.investmentChoices && (
                <FormHelperText error={true}>
                  {errors.investmentChoices}
                </FormHelperText>
              )}
            </>
          ) : null}

          {values.fundChoices === "select_my_own_funds" && (
            <MyFundOptions
              investmentChoicesOptions={nonLifeStyleFunds}
              handleNingiChange={handleNingiChange}
            />
          )}

          {values.fundChoices === "fund_by_westminster_wealth" ? (
            !userWithNingiRecommendation ||
            !userWithNingiRecommendation.length ? (
              <NingiStart />
            ) : form?.ningi_journey?.journey_status ===
              NINGI_JOURNEY_STATUS.INCOMPLETED_NINGI_JOURNEY ? (
              <NingiContinue />
            ) : (
              form?.ningi_journey?.journey_status ===
                NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY && (
                <FundsRecommended />
              )
            )
          ) : null}

          <FooterButtons />
          <Typography sx={{ fontSize: "15px", mt: 1 }}>
            *There is a default fund set. Please note that this is not a
            recommendation and you should choose fund options to suit your
            needs.
          </Typography>
        </Box>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => {
  console.log({ state });
  return {
    form: state.form.form,
    isNHSApplication: isNHSApplication(state),
    pensionType: state.multiForm.pensionType,
  };
};

FundChoices.propTypes = {
  form: PropTypes.object,
  pensionType: PropTypes.string,
  isNHSApplication: PropTypes.bool,
};

export default connect(mapStateToProps)(FundChoices);
