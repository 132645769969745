import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { extingPrudentialAvcPlanSchema } from "../../../../../schema";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import SavePlan from "../../../../generic-components/save-plan";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import {
  findItemBySelectedValue,
  booleanToYesNo,
} from "../../../../../helpers";
import useUserEvent from "../../../../../hooks/useUserEvent";
import DateInput from "../../../../generic-components/date-input";
import isEmpty from "lodash/isEmpty";
import { fetchLinks } from "../../../../../graphql/queries/fetchLinks";
import { useLazyQuery } from "@apollo/client";
import { getLegacyAppURL } from "../../../../../helpers";

const ExistingPrudentialAvcPlan = ({ form, organisation, scheme }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
    personalDetails,
  } = useStepper();

  const dispatch = useDispatch();
  const initialValues = {
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    combiningWithThisEmployer: formValues.combiningWithThisEmployer,
    keepSeparateWithEmployer: formValues.keepSeparateWithEmployer,
    triggeredTheMpaa: booleanToYesNo(formValues.triggeredTheMpaa),
  };

  const [updateFunds] = useMutation(updateFundDetailOnly);

  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();
  const [faqLink, setFaqLink] = useState();

  const [getLinks] = useLazyQuery(fetchLinks, {
    variables: {
      scheme_id: scheme.scheme_id,
    },
    onCompleted: (data) => {
      setFaqLink(
        data.scheme_details.faq.startsWith("https") ||
          data.scheme_details.faq.startsWith("http")
          ? data.scheme_details.faq
          : `${getLegacyAppURL(personalDetails?.sub_domain)}${
              data.scheme_details.faq
            }`
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getLinks();
  }, []);

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  const saveExisting = (data) => {
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(false);
      handleNext();
    } else {
      setIsLoadingSave(false);
      setSavedToast(true);
    }

    dispatch(setForm(data.update_temp_plans.returning[0]));
  };

  const saveExistingError = (error) => {
    setIsLoadingSave(false);
    setSaveError(error);
    setIsLoadingNext(false);
    setErrorToast(true);
  };

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        combining_with_this_employer:
          values.combiningWithThisEmployer === "Yes" ? true : false,
        keep_separate_with_employer:
          values.keepSeparateWithEmployer === "Yes" ? true : false,
        triggered_the_mpaa: values.triggeredTheMpaa === "Yes" ? true : false,
        mpaa_date:
          values.triggeredTheMpaa && values.MPAAdate ? values.MPAAdate : null,
        step_number: btnClicked === "save" ? activeStep + 6 : activeStep + 7,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        saveExisting(data);
      },
      onError: (error) => {
        saveExistingError(error);
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: extingPrudentialAvcPlanSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const { handleSubmit, values, handleBlur, touched, errors, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="investment-choices-step-2">
          <ScrollToFieldError />
          <SavePlan />
          <Box className="application-page-container">
            <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
              <Typography>
                <strong>Existing Prudential AVC plan</strong>
              </Typography>
            </Box>
            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend" sx={{ mt: 2 }}>
                Are you combining your LGPS from your previous employer with
                your {organisation?.name ?? ""} scheme?
              </FormLabel>
              <RadioGroup
                name="combiningWithThisEmployer"
                value={booleanToYesNo(values.combiningWithThisEmployer)}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  handleUserEvent(event);
                  setFieldValue("combiningWithThisEmployer", selectedValue);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    combiningWithThisEmployer: selectedValue,
                  }));
                }}
              >
                <FormControlLabel
                  value={"Yes"}
                  control={
                    <Radio
                      sx={{
                        color: "#2C2C2C",
                        "&.Mui-checked": {
                          color: "#2C2C2C",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={"No"}
                  control={
                    <Radio
                      sx={{
                        color: "#2C2C2C",
                        "&.Mui-checked": {
                          color: "#2C2C2C",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
              {touched.combiningWithThisEmployer &&
                errors.combiningWithThisEmployer && (
                  <FormHelperText error={true}>
                    {errors.combiningWithThisEmployer}
                  </FormHelperText>
                )}
            </FormControl>
            <Box
              sx={{
                paddingTop: 3,
                paddingBottom: 3,
                backgroundColor:
                  booleanToYesNo(values.combiningWithThisEmployer) === "Yes"
                    ? "transparent"
                    : "#D9D9D9",
                opacity:
                  booleanToYesNo(values.combiningWithThisEmployer) === "Yes"
                    ? 1
                    : 0.6,
                paddingLeft:
                  booleanToYesNo(values.combiningWithThisEmployer) === "Yes"
                    ? 0
                    : 1,
              }}
            >
              <Box
                sx={{
                  p: 3,
                  backgroundColor:
                    booleanToYesNo(values.combiningWithThisEmployer) === "Yes"
                      ? "#FEF9FF"
                      : "#D9D9D9",
                }}
              >
                <div className="img-container">
                  <img
                    className="logo-dark"
                    src={pensionFundIcon}
                    alt="logo-dark"
                  />
                </div>
                <Typography className="mt-30 heading">
                  <strong>Considering combining AVC plans?</strong>
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  Read the last page of this{" "}
                  <a
                    href={faqLink}
                    className="web-link"
                    target="_blank"
                    rel="noreferrer"
                    name="link_mandg"
                    onClick={handleUserEvent}
                  >
                    FAQ
                  </a>{" "}
                  to help you to decide
                </Typography>
              </Box>
              <FormControl component="fieldset" sx={{ mt: 2 }}>
                <FormLabel component="legend" sx={{ mt: 2 }}>
                  If you’ve an AVC plan with Prudential, are you keeping your
                  AVC plan with your previous employer separate to your AVC plan
                  with this employer?
                </FormLabel>
                <RadioGroup
                  name="keepSeparateWithEmployer"
                  value={booleanToYesNo(values.keepSeparateWithEmployer)}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    handleUserEvent(event);
                    setFieldValue("keepSeparateWithEmployer", selectedValue);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      keepSeparateWithEmployer: selectedValue,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={"Yes"}
                    control={
                      <Radio
                        sx={{
                          color: "#2C2C2C",
                          "&.Mui-checked": {
                            color: "#2C2C2C",
                          },
                        }}
                      />
                    }
                    disabled={
                      booleanToYesNo(values.combiningWithThisEmployer) === "No"
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"No"}
                    disabled={
                      booleanToYesNo(values.combiningWithThisEmployer) === "No"
                    }
                    control={
                      <Radio
                        sx={{
                          color: "#2C2C2C",
                          "&.Mui-checked": {
                            color: "#2C2C2C",
                          },
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Box sx={{ p: 3, bgcolor: "#FEF9FF", marginTop: "20px" }}>
                <div className="img-container">
                  <img
                    className="logo-dark"
                    src={pensionFundIcon}
                    alt="logo-dark"
                  />
                </div>
                <Typography className="mt-30 heading">
                  <strong>The Money Purchase Annual Allowance (MPAA)</strong>
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  If you’ve taken some money out of a pension pot, then you may
                  have triggered the MPAA. If so, this application could be
                  affected.
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  To learn more, read{" "}
                  <strong>
                    Important information about pensions allowances
                  </strong>{" "}
                  You can find this on
                  <a
                    href="https://www.mandg.com/pru/workplace-pensions/employees/public-sector-avc-schemes/local-gov"
                    className="web-link"
                    target="_blank"
                    rel="noreferrer"
                    name="link_mandg"
                    onClick={handleUserEvent}
                  >
                    FAQ
                  </a>{" "}
                  to help you to decide
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  If you’re still unsure, speak to your financial advisor.
                </Typography>
              </Box>
              <FormControl component="fieldset" sx={{ mt: 2 }}>
                <FormLabel component="legend" sx={{ mt: 2 }}>
                  Has your pension scheme or provider told you that you’ve
                  triggered the MPAA?
                </FormLabel>
                <RadioGroup
                  name="triggeredTheMpaa"
                  value={booleanToYesNo(values.triggeredTheMpaa)}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    handleUserEvent(event);
                    setFieldValue("triggeredTheMpaa", selectedValue);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      triggeredTheMpaa: selectedValue,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={"Yes"}
                    control={
                      <Radio
                        sx={{
                          color: "#2C2C2C",
                          "&.Mui-checked": {
                            color: "#2C2C2C",
                          },
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"No"}
                    control={
                      <Radio
                        sx={{
                          color: "#2C2C2C",
                          "&.Mui-checked": {
                            color: "#2C2C2C",
                          },
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                {touched.triggeredTheMpaa && errors.triggeredTheMpaa && (
                  <FormHelperText error={true}>
                    {errors.triggeredTheMpaa}
                  </FormHelperText>
                )}
              </FormControl>
              <DateInput
                id="MPAAdate"
                name="MPAAdate"
                label="MPAA Date"
                disabled={
                  isEmpty(values.triggeredTheMpaa) ||
                  values.triggeredTheMpaa === "No"
                }
                minDate={moment("2015-01-01")}
                maxDate={moment()}
                trackingDetails={TRACKING_NAF_OPTIONS}
                value={values.MPAAdate}
                handleChangeValue={(value) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    MPAAdate: moment(value).format("YYYY-MM-DD"),
                  }));
                }}
                handleBlurValue={handleBlur}
                styleClass="stepper-field"
              />
            </Box>
            <FooterButtons />
          </Box>
        </Box>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    scheme: state.scheme.scheme,
    pensionName: state.multiForm.pensionName,
    organisation: state?.organisation?.organisation,
  };
};

ExistingPrudentialAvcPlan.propTypes = {
  form: PropTypes.object,
  pensionName: PropTypes.string,
  organisation: PropTypes.object,
  scheme: PropTypes.object,
};

export default connect(mapStateToProps)(ExistingPrudentialAvcPlan);
