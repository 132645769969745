import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import React, { useEffect } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { extingPrudentialPlanSchema } from "../../../../../schema";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import SavePlan from "../../../../generic-components/save-plan";
import {
  findItemBySelectedValue,
  booleanToYesNo,
} from "../../../../../helpers";
import useUserEvent from "../../../../../hooks/useUserEvent";

const ExistingPrudentialPensionPlan = ({ form, pensionType }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
  } = useStepper();

  const dispatch = useDispatch();
  const initialValues = {
    previousEmployerPlan: formValues.previousEmployerPlan,
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
  };

  const [updateFunds] = useMutation(updateFundDetailOnly);

  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  const hanldeFormSubmit = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        having_previous_employer_plan: values.previousEmployerPlan === "Yes",
        step_number: btnClicked === "save" ? activeStep + 5 : activeStep + 6,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        if (btnClicked === "save_and_next") {
          setIsLoadingNext(false);
          handleNext();
        } else {
          setIsLoadingSave(false);
          setSavedToast(true);
        }

        dispatch(setForm(data.update_temp_plans.returning[0]));
      },
      onError: (error) => {
        setIsLoadingSave(false);
        setSaveError(error);
        setIsLoadingNext(false);
        setErrorToast(true);
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: extingPrudentialPlanSchema,
    onSubmit: (values) => {
      hanldeFormSubmit(values);
    },
  });

  const { handleSubmit, values, touched, errors, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="investment-choices-step-2">
          <ScrollToFieldError />
          <SavePlan />
          <Box className="application-page-container">
            <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
              <Typography>
                <strong>Existing Prudential Pensions</strong>
              </Typography>
            </Box>
            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend" sx={{ mt: 2 }}>
                Do you have an AVC plan with Prudential from a previous{" "}
                {pensionType}
                {""} employer?
              </FormLabel>
              <RadioGroup
                name="previousEmployerPlan"
                value={booleanToYesNo(values.previousEmployerPlan)}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  handleUserEvent(event);
                  setFieldValue("previousEmployerPlan", selectedValue);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    previousEmployerPlan: selectedValue,
                  }));
                }}
              >
                <FormControlLabel
                  value={"Yes"}
                  control={
                    <Radio
                      sx={{
                        color: "#2C2C2C",
                        "&.Mui-checked": {
                          color: "#2C2C2C",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={"No"}
                  control={
                    <Radio
                      sx={{
                        color: "#2C2C2C",
                        "&.Mui-checked": {
                          color: "#2C2C2C",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
              {touched.previousEmployerPlan && errors.previousEmployerPlan && (
                <FormHelperText error={true}>
                  {errors.previousEmployerPlan}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <FooterButtons />
        </Box>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    // isNHSApplication: isNHSApplication(state),
    pensionType: state.multiForm.pensionType,
  };
};

ExistingPrudentialPensionPlan.propTypes = {
  form: PropTypes.object,
  pensionType: PropTypes.string,
  // isNHSApplication: PropTypes.bool,
};

export default connect(mapStateToProps)(ExistingPrudentialPensionPlan);
