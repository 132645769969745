import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { investmentChoiceSchema } from "../../../../../schema";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import SavePlan from "../../../../generic-components/save-plan";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import {
  findItemBySelectedValue,
  booleanToYesNo,
} from "../../../../../helpers";
import pensionFundIcon from "../../../../../assets/images/pension-fund.png";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { getInvestmentOptions } from "../../../../../graphql/queries/investmentOptions";
import {
  createInvestmentChoice,
  deleteInvestmentChoice,
} from "../../../../../graphql/mutations/investmentChoice";

const InvestmentChoices = ({ form }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
  } = useStepper();

  const dispatch = useDispatch();
  const initialValues = {
    previousEmployerPlan: formValues.previousEmployerPlan,
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    investmentChoice: null,
  };
  const [defaultInvestmentOption, setDefaultInvestmentOption] = useState(null);
  const [tempInvestmentChoice] = useMutation(createInvestmentChoice);
  const [deleteTempInvestment] = useMutation(deleteInvestmentChoice);
  console.log({ defaultInvestmentOption });
  const [investmentChoicesOptions, setInvestmentChoicesOptions] = useState([
    {
      label: "My own selection",
      value: "2",
    },
  ]);

  const [updateFunds] = useMutation(updateFundDetailOnly);

  const { userTrackingMutation } = useUserEvent();

  const { trackingCode } = useStepper();

  const [getInvestmentChoicesOptions] = useLazyQuery(getInvestmentOptions, {
    onCompleted: (data) => {
      if (
        !(
          data.investment_options_pension_funds.length &&
          data.investment_options.length
        )
      ) {
        return;
      }

      const defaultPensionFund = data.investment_options_pension_funds.find(
        (option) => option
      );

      const isDefaultOptionAvailable = data.investment_options.find(
        (option) => option.id === defaultPensionFund?.investment_option?.id
      );

      if (!isDefaultOptionAvailable) {
        return;
      }

      setDefaultInvestmentOption(defaultPensionFund?.investment_option);
      setInvestmentChoicesOptions([
        { label: "Your scheme's default investment option*", value: "1" },
        ...investmentChoicesOptions,
      ]);

      console.log(formValues.investmentChoices, "choices");
      if (formValues.investmentChoices.length) {
        const isTempPlanContainsDefaultInvestmentOption =
          formValues.investmentChoices.some(
            (item) => item.value === defaultPensionFund?.investment_option.id
          );
        setFieldValue(
          "investmentChoice",
          isTempPlanContainsDefaultInvestmentOption ? "1" : "2"
        );
      } else {
        setFieldValue("investmentChoice", null);
      }
    },
    onError: (error) => {
      console.log(error);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    if (formValues.pensionFund && formValues.provider) {
      getInvestmentChoicesOptions({
        variables: {
          pensionId: formValues.pensionFund,
          providerId: formValues.provider,
        },
      });
    }
  }, [formValues.pensionFund, formValues.provider]);

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  const createTempInvestment = (investmetnOptionValue) => {
    const payload = {
      investment_option_id: defaultInvestmentOption.id,
      temp_plan_id: form.id,
      specialist_value: 100,
      default_option: "true",
      created_at: moment().format(),
      updated_at: moment().format(),
    };
    tempInvestmentChoice({
      variables: {
        investmentOptions: { ...payload },
      },
      onCompleted: () => {
        dispatch(
          setForm({ investmentChoices: investmetnOptionValue.investmentChoice })
        );
        setFormValues((prevValues) => ({
          ...prevValues,
          investmentChoices: investmetnOptionValue.investmentChoice,
        }));
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const onCompletedAction = (data) => {
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(false);
      handleNext();
    } else {
      setIsLoadingSave(false);
      setSavedToast(true);
    }

    dispatch(setForm(data.update_temp_plans.returning[0]));
    if (values.investmentChoice === "1") {
      deleteTempInvestment({
        variables: {
          temp_plan_id: form.id,
        },
        onCompleted: () => createTempInvestment(values),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const onErrorAction = (error) => {
    setIsLoadingSave(false);
    setSaveError(error);
    setIsLoadingNext(false);
    setErrorToast(true);
  };

  const handleUpdate = (values) => {
    setSaveError("");
    console.log("Hey Vikas, I am sdfasdfsed", values);
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        having_previous_employer_plan:
          values.previousEmployerPlan === "Yes" ? true : false,
        step_number: btnClicked === "save" ? activeStep + 7 : activeStep + 8,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        onCompletedAction(data);
      },
      onError: (error) => {
        onErrorAction(error);
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: investmentChoiceSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const { handleSubmit, values, touched, errors, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="investment-choices-step-2">
          <ScrollToFieldError />
          <SavePlan />
          <Box className="application-page-container">
            <Box sx={{ bgcolor: "#FAE2FE", p: 1.5, mb: 3 }}>
              <Typography>
                <strong>Existing Prudential Pensions</strong>
              </Typography>
            </Box>
            <Box sx={{ p: 3, bgcolor: "#FEF9FF" }}>
              <div className="img-container">
                <img
                  className="logo-dark"
                  src={pensionFundIcon}
                  alt="logo-dark"
                />
              </div>
              <Typography className="mt-30 heading">
                <strong>Select which funds your money is invested into.</strong>
              </Typography>
              <Typography sx={{ fontSize: "15px", mt: 1 }}>
                Now you can choose exactly where your money should be invested.
              </Typography>
            </Box>
            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend" sx={{ mt: 2 }}>
                Which type would you like?
              </FormLabel>
              <RadioGroup
                name="investmentChoice"
                value={booleanToYesNo(values.investmentChoice)}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  console.log("Selected Values", selectedValue);
                  handleUserEvent(event);
                  setFieldValue("investmentChoice", selectedValue);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    investmentChoice: selectedValue,
                  }));
                }}
              >
                {investmentChoicesOptions.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Radio
                        sx={{
                          color: "#2C2C2C",
                          "&.Mui-checked": {
                            color: "#2C2C2C",
                          },
                        }}
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
              {touched.investmentChoice && errors.investmentChoice && (
                <FormHelperText error={true}>
                  {errors.investmentChoice}
                </FormHelperText>
              )}
              {values.investmentChoice === "1" ? (
                <Box
                  sx={{
                    bgcolor: "#FAE2FE",
                    p: 1.5,
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "500px",
                    mt: 8,
                  }}
                >
                  <Typography>
                    {defaultInvestmentOption?.display_name}
                  </Typography>
                  <Typography>
                    <strong>
                      100 <span>%</span>{" "}
                    </strong>
                  </Typography>
                  <Typography>
                    <strong> {formValues?.max_contribution_amount}</strong>
                  </Typography>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          <FooterButtons />
          <Typography sx={{ fontSize: "15px", mt: 1 }}>
            *There is a default fund set. Please note that this is not a
            recommendation and you should choose fund options to suit your
            needs.
          </Typography>
        </Box>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    isNHSApplication: isNHSApplication(state),
    pensionType: state.multiForm.pensionType,
  };
};

InvestmentChoices.propTypes = {
  form: PropTypes.object,
  pensionType: PropTypes.string,
  isNHSApplication: PropTypes.bool,
};

export default connect(mapStateToProps)(InvestmentChoices);
